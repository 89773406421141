
let baseurl = "https://localhost:44347";
let callBackUrl = "https://localhost:3000/callback"
let api_uri = "https://api.esassoc.net/api/FulcrumData/";
let api_base = "https://api.esassoc.net/api/"
let ClientName = "";
let MAX_ITEM_ZIP = 200;

let reportApi = "Reports/ComplianceRpt?qry=";
let Logo = '/media/logo.png'
let API_KEY = "cdbb18f9e1b9c1421f60bea34e159a5d2cfb5f3be855d690aef0f398412f1e29c63847d9a5dc49f8";
let title = " !Development!";
let CC_QueryFields = [
                        {name: 'Site Name',field : 'site_name'},
                        {name: 'Sub Site Name',field : 'sub_site'},
                        {name: 'Resolved',field : 'concern_resolved'},
                        {name: 'Concern Group',field : 'compliance_concern_group'},
                       
                        
                      
                    ] 

let CC_Fields = [
  {name:'ID',field: '__esa_id'},
  {name: 'Maint?',field : 'maint_item', selected:[]},
  {name:'Days Open', field:'daysOpen'},  
  {name: 'Inital Date',field : 'initial_date', selected:[]},

                        {name: 'Site Name',field : 'site_name',selected:[]},
                        {name: 'Sub Site Name',field : 'sub_site',selected:[]},
                      
                        {name: 'Concern Status',field : '_status',selected:[]},
                        {name: 'Compliance Concern',field : 'compliance_concern',selected:[]},
                        {name: 'Concern Rating',field : 'Concern_Rating',selected:[]},
                        {name: 'Notes',field : 'concern_notes',selected:[]},
                        {name: 'Resolution Date',field:'resolution_date',selected:[]}
                         
]

//"site_name,concern_resolved,compliance_concern,compliance_concern_group,sub_site"
//http://gistest02.azurewebsites.net/
 
let tbColor = 'rgba(0,0,255,0.4)';
const report_DisplayFields =['_record_id', 'flg_final  as "__final"','date','field_person as "Field Biologist"','name_of_qa_person as "QC Biologist"', '_status as Status'];

const textSubs = [
  {org:"QCC", value:"Quality Checks Completed, ready for upload to EMMA"},
  {org:"QC", value:"Currently under review for QC"},
  {org:"FS", value:"Field submitted and ready for QC"},
  {org:"New", value:"Field review in progress, not ready for QC"}

]
const status_colors = {
  'New' : '#CB0D0C',
  'On Going' : '#F5CB00',
  'Resolved':'#87D30F'

}
const roles = {
//Azure Security Groups
//Admins 	a5fef2f3-f6f2-4205-b7a9-d26ff7314c07
//Reg users App-Field-Forms-User /d5850d61-4550-4b40-a204-37a535fd37da
//Guests, neither 
    admin: 'a5fef2f3-f6f2-4205-b7a9-d26ff7314c07',
    user : 'd5850d61-4550-4b40-a204-37a535fd37da'
}


if (process.env.NODE_ENV === "production") {
    baseurl = "https://chst.esassoc.net/";
    callBackUrl = "https://chst.esassoc.net/callback";
    api_uri = "https://api.esassoc.net/api/FulcrumData/";
    title = "High Speed Train - Compliance Concerns"
    api_base = "https://api.esassoc.net/api/";
    let tbColor = 'rgba(0,0,255,0.6)';
  }
const config = {
    Logo,tbColor,
    roles,CC_QueryFields,CC_Fields,
    report_DisplayFields,textSubs,
    baseurl,api_uri,api_base,
    ClientName, callBackUrl,  API_KEY, title,status_colors,appId: '7b5c85de-88e4-4dda-b927-a3c37edd657b',MAX_ITEM_ZIP,
    scopes: [
        "user.read"
    ],reportApi
};

export default config