import { UserAgentApplication } from 'msal';
import React, { Component } from 'react';
import App from '../../App';
import config from '../../config';
import { getGroups, getUserDetails } from '../../GraphService';
import { AuthProvider } from './context';
import { BrowserRouter } from 'react-router-dom';



class Auth extends Component {
  constructor(props) {
    super(props);

    this.userAgentApplication = new UserAgentApplication({
        auth: {
            clientId: config.appId
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    });
    var user = this.userAgentApplication.getAccount();
    function checkState(){
     
      var _cache  = localStorage.getItem('cacheState');
   //var _oCache = JSON.parse(_cache);
     console.log(_cache);//_cache !==null && _cache!==undefined ? _oCache:
    }
     

    //Some how copy state on refresh. This should keep the user logged in and not boot back to main page on refresh.

//stackoverflow.com/questions/28314368/how-to-maintain-state-after-a-page-refresh-in-react-js





    this.state =  {
      user:{}, authenticated:false, role:localStorage.getItem('role') || 'guester', token:  localStorage.getItem('groups') || "", groups:JSON.parse(localStorage.getItem('groups')) || [], level:'', value:undefined, isAuthenticated: localStorage.getItem('isAuthenticated') || false,login:this.login.bind(this), logout:this.logout.bind(this)
    };

    if (user) {
      // Enhance user object with data from Graph
      this.getUserProfile();
    }
   
  
  
      }
  render() {


    return (
      
     
  this.state.user !== {} &&<BrowserRouter>
<AuthProvider value={{...this.state}}>
 <App/> 
    </AuthProvider> 
    </BrowserRouter>
 
      
      
            
    )
  }

  setErrorMessage(message, debug) {
    this.setState({
      error: {message: message, debug: debug}
    });
  }

  async login() {
   
    try {
      await this.userAgentApplication.loginPopup(
        {
          scopes: config.scopes,
          prompt: "select_account"
      });
      await this.getUserProfile();
    }
    catch(err) {
      console.log(err)
      alert(err)
      var errParts = err.split('|');
      this.setState({
        isAuthenticated: false,
        user: {},
        error: { message: errParts[1], debug: errParts[0] }
      });
    }
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async getUserProfile() {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token
      
  
      var accessToken=  localStorage.getItem('token');
   
     
     var accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: config.scopes
      }); 
      var roll = 'guest'
      if (accessToken) {
        // Get the user's profile from Graph
        var tmp = localStorage.getItem('user');
        var jTemp = JSON.parse(tmp);
  
        var user = jTemp!==null ?   jTemp : await getUserDetails(accessToken);
        var groups = await getGroups(accessToken)
        
     
      
       roll =  groups.value.find(a=>a.id===config.roles.user) !== undefined ? 'user' : 'guest'
       roll = groups.value.find(a=>a.id===config.roles.admin) !== undefined ? 'admin' : roll
      
        this.setState({
        role: roll,
          isAuthenticated: true,
          user: user ,
           groups: groups,
           authenticated: true,
          error: null
          },

        );
     //  AuthProvider.value = this.state;
 
         localStorage.setItem('role',  roll );
         localStorage.setItem('groups', JSON.stringify(groups))
        localStorage.setItem('token',accessToken)
        localStorage.setItem('user', JSON.stringify(user))
      }
    }
    catch(err) {
      console.log(err)
      var error = {};
      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }

      this.setState({
        isAuthenticated: false,
        user: {},
        error: error
      });
    }
  }
}

export default Auth;
