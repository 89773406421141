const rules = {
    fulcrum:{
      
    },
    visitor: {
      static: ["cc:list", "home-page:visit"]
    },
    writer: {
      static: [
        "cc:list",
        "cc:create",
        "users:getSelf",
        "home-page:visit",
        "dashboard-page:visit",
        "dataType-all:view"
      ],
      dynamic: {
        "cc:edit": ({userId, ccOwnerID}) => {
          if (!userId || !ccOwnerID) return false;
          return userId === ccOwnerID;
        }
      }
    },
    user: {
      static: [
        "cc:list",
        "cc:create",
        "cc:edit",
        "cc:delete",
        "users:get",
        "users:getSelf",
        "home-page:visit",
        "dashboard-page:visit",
        "dataType-all:crud"
      ]
    },
    admin: {
      static: [
        "cc:list",
        "cc:create",
        "cc:edit",
        "cc:delete",
        "users:get",
        "users:getSelf",
        "home-page:visit",
        "dashboard-page:visit",
        "dataType-all:crud"
      ]
    }
  };
  
  export default rules;