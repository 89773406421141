import React, { useState } from 'react';
import ReactMapGL, {NavigationControl}  from 'react-map-gl';
import { makeStyles } from '@material-ui/core/styles';
import Pins from './markerLayer'
import { PromptState } from 'msal/lib-commonjs/utils/Constants';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import ComplianceConern from '../hst/complianceConcern';
import 'typeface-roboto';
import { DialogContent, Drawer, Button, Paper, Fab, Typography } from '@material-ui/core';
import {groups} from '../hst/ccGroupIcon'
import { Translate } from '@material-ui/icons';
const TOKEN = "pk.eyJ1IjoiZXNhZ2lzIiwiYSI6ImV3dnkyWjAifQ.SYBjFR-Bw0YDntotGjtZxQ";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
 
  
  legendItem:{
    backgroundColor: 'rgba(104, 102, 102, 0.85)', color:'rgba(255, 255, 255, 0.85)', padding:'5px'
  },
  fullList: {
    width: 'auto',
  },
});
export default function Map(props) {
  const classes = useStyles();
    const [data, setData] = useState(props.data);
    const [selectedId, SetSelectedId] = useState();
    const [open,setOpen] = useState(false);
    const [title,setTitle] = useState("Compliance Concern");
    const [ccId, setccId] = useState();
    const [drawerOpen,setDrawerOpen]  =useState(false);
      const [viewport, setViewport] = useState({
    
        width: 400,
    height: 400,
    latitude: 37.3022,
    longitude: -120.4830,

    zoom: 8
  });
  const { onClose, selectedValue } = props;

  const handleClose = () => {
    setOpen(false);
  };

  const handleListItemClick = value => {
   setOpen(false);
  };
  function markerClick(cc){
      setccId(cc.ccId);
      setTitle(cc.title);
      setOpen(true);
  }
  return (
      <>

    <ReactMapGL id="drawer-container"
      {...viewport}
      onViewportChange={setViewport}
      mapboxApiAccessToken={TOKEN}
      width={window.innerWidth-75}
      height={window.innerHeight-200}
       
     /* latitude={37.78}
      longitude={-122.45}
      zoom={11}*/
     // mapStyle={"mapbox://styles/mapbox/dark-v9"}
     mapStyle={"mapbox://styles/esagis/ck5xk55g111ic1iq6c2qawozl"}
    
    >


    
<Drawer   PaperProps={{ style: { position: 'absolute' } }}
  BackdropProps={{ style: { position: 'absolute' } }}
  ModalProps={{
    container: document.getElementById('drawer-container'),
    style: { position: 'absolute', backgroundColor:'transparent', background:'transparent' }
  }} variant="persistent" onClick={()=>setDrawerOpen(false)} open={drawerOpen}>
          
           
            { groups.map(a=>
            <Paper elevation={4} className={classes.legendItem}>
        <Typography variant="body1"  >   <Fab size="small">{a.icon}</Fab>  {a.group}</Typography>  </Paper>
              )}
    
         
            </Drawer>     
          
 <Button color={"primary"} onClick={()=>setDrawerOpen(true)}>Legend</Button>
     
            <Pins onClick={markerClick} icoSize={viewport.zoom} data={data}></Pins>
            <div style={{position: 'absolute', right: 0}}>
          <NavigationControl  />
        </div>
        </ReactMapGL>

        <Dialog className="ccDialog" onClose={handleClose} aria-labelledby="simple-dialog-title" maxWidth={false} open={open}>

      <DialogTitle className="mapDialog" id="simple-dialog-title">{title}</DialogTitle>
        <DialogContent><ComplianceConern unrestrictedWidth={true} ccid={ccId} exp={true}></ComplianceConern></DialogContent>
    </Dialog>

        </>
  );
}