import { Client } from 'fulcrum-app';
import React from 'react';

import config from '../config';
import { isNumber } from 'is-what';
const client = new Client(config.API_KEY);
 function getForms(cb){
    //console.log('getting forms')
    client.forms.all({schema: false})
  .then((page) => {
  
    cb(page.objects)
    return page.objects;
  })
  .catch((error) => {
    //console.log('Error getting your forms.', error.message);
  });
 }
 
async function getDistinctMetric(form, metric){
  return await   Query('SELECT DISTINCT(' + metric +  ') as metric from "' + form + '" where ' + metric + ' IS NOT NULL')
}
async function getDistinctMetricToArr(form,metric){
  let x = [];
  let y = await getDistinctMetric(form,metric);
  y.rows.map(a=>
      x.push(a.metric === 'undefined' ? 'Unknown' : a.metric)
    )
    return x; 
}
//Expects formname and array of name value pairs for filters
async function getRecordsByFilter(form, filters){
  var where = ''
  console.log(filters)
  var i =0 
  filters.map(a=>
    {
      if(i!==0){
        where+= ' AND '
      }
      where+= a.name + ' = ' +  (isNumber(a.value)  ?   ' ' + a.value :' \'' + a.value + '\'')
      i++;
    }

    )
    var query = 'SELECT * FROM "' + form + '" WHERE ' + where;
    
    //For CC report download
    window.localStorage.setItem('cc_Query', query);
    let x = await Query(query)
    console.log(query)
    return x.rows;
}
async function toArr(y){
  let x = [];
  y.rows.map(a=>
    x.push(a.metric === 'undefined' ? 'Unknown' : a.metric)
    )
    return x; 
}
async function store(name,obj){
 
 await localStorage.setItem(name,JSON.stringify(obj)) ;
}
async function getItem(name){
 
 return await JSON.parse(await localStorage.getItem(name)) ;
}
async function getPieData(form, metric, filter){
 
  var query = filter !== undefined && filter.field !== 'none' ? 'SELECT ' + metric +' as metric, Count(*) as cnt from "' + form  + '" WHERE ' + filter.field + ' ' + filter.op + '  \'' + filter.value + '\' GROUP BY ' + metric :
          'SELECT ' + metric +' as metric, Count(*) as cnt from "' + form  + '" GROUP BY ' + metric;
  var data = await Query(query);
  let tmp = [];
  data.rows.map(a=>{
      tmp.push({"value" : a.cnt, "id" : a.metric, "label" : a.metric })
  })
  return tmp;
}
async function getHeatMatrix(form,metric1, metric2){
  var ds_met1 = await getDistinctMetric(form, metric1);
  var ds_met2 = await getDistinctMetric(form, metric2);
  var ret = [];
  const { map } = require('p-iteration');

  await map(  ds_met1.rows, async a => {
    var retData= { }
     
    retData[metric1] =   a.metric === 'undefined' ? "Unknown" : a.metric

   // var tmpSQL = 'select t2.'+ metric2 + ' as metric, count(t1.'+metric2+ ') as cnt from "'+ form + '" t2 LEFT JOIN (SELECT '+metric2+' from "'+form+'" WHERE '+metric1+' = \'' + a.metric + '\' ) t1 ON  t1.'+ metric2 +' = t2.'+metric2+' where t2.'+metric2+' is not null   GROUP BY metric' 
   var tmpSQL = 'select DISTINCT(t1.'+ metric2 + ') as metric,_status, t2.cnt from "'+ form + '" t1 LEFT JOIN (SELECT count(*) as cnt , '+metric2+' from "'+form+'" WHERE '+metric1+' = \'' + a.metric + '\'  Group by  '+metric2+' ) t2 ON  t1.'+ metric2 +' = t2.'+metric2 
   
   try{
      var tmp =  await Query(tmpSQL); 
      //console.log(tmp)
       tmp.rows.map( b=>{
          retData[b.metric]=  b.cnt===null ? 0:b.cnt ;
         retData[b.metric+ 'Color'] = "hsl(266, 70%, 50%)";
         retData[b.metric + 'status'] = b._status
      })  
       ret.push(retData);
    }catch(err){
      console.log(  err);
      console.log("ERROR!!" + tmpSQL);
      //alert(tmpSQL);
    }
  })

    var retObj = {
      matrix:ret,
      index:await toArr(ds_met2),
    }
    //console.log(retObj)
    return retObj;

  }
  

 


async function getDailyCount(form){
  const res =await  Query('SELECT dateadd(DAY,0, datediff(day,0, _updated_at)) AS day, COUNT(*) as value FROM "' + form + '" GROUP BY dateadd(DAY,0, datediff(day,0, _updated_at))')
  //console.log(res)
  return res.rows;  

  
  


}
function getLineSeries(rows,xfield,yfield,seriesName){

  let data = [];
  rows.map(row=>data.push({x:row[xfield], y:row[yfield]}))
  let ret = {
    data:data,
    id:seriesName
  }
  return ret;
}

async function storeQryCache(qry,data){
  let cache = 
    {
      query:qry,
      data:data,
      date:new Date()
    }


let item = await getItem('queryCache')
  await item.push(cache)
  await store('queryCache', item)

}
async function getQueryCache(qry){
  let item = await getItem('queryCache')
  if(item === null){
    let cache = [
        {
          query:qry,
          data:null,
          date:new Date()
        }

    ]
    await store('queryCache',cache)
   
    
  }
  item = await getItem('queryCache') 
   let ret = item.find(a=>a.query===qry)
 
    return ret
     
    
   

}
async function Query(qry,overWriteCache){
 var disableCaching = true;
  //url encode forward slashes
   
  qry =  encodeURI(qry);
  
  qry = qry.replace("/","!1000!"); //can't have these in the URL - API Must handle replacement.
 // alert(qry);
  if(overWriteCache===undefined && !disableCaching){
  let item = await getQueryCache(qry);
  console.log(item)
  if(item!==null && item !==undefined ){
    if(item.data!==null && item.data !== undefined){
        console.log('returning item from cache')
      
        return item.data
    }
  }
}
console.log('returning item live site')
 // var uri = "https://api.fulcrumapp.com/api/v2/query?token=" + config.API_KEY + "&format=json&q=";
 var uri = config.api_uri;

  var query = qry;
 
  uri+=query;
 
 const resp = await fetch(uri)
  console.log(uri);
 //console.log(resp);
 const x = await resp.json()  
  if(!disableCaching)
    await storeQryCache(qry,x)
  return x;
 
  
}
function SpQuery(what, where, isChild){
 // getChildObject(what);
 
  var subTable = ''
 

  //var uri = "https://api.fulcrumapp.com/api/v2/query?token=" + config.API_KEY + "&format=json&q=";
  var uri = config.api_uri;
  var query = 'Select * from "' + what + '' + subTable + '" where '+ (isChild ? 'parentid' : '_record_id') +  '=\'' + where + '\'';
 
  query =  encodeURI(query);
  query  = query.replace("/","!1000!"); //can't have these in the URL - API Must handle replacement.


 console.log(query)
  uri+=query;
  //console.log(uri);
  fetch(uri)
  .then(response => {
    if (response.ok) {
       response.json().then(data =>
        {//console.log(what)
        console.log(data)
      }
        )
     
    } else {
    
      throw new Error('Something went wrong ...');
    }
  })
}
/*
function fulcrum() {
 const [forms,setForms] = React.useState([])
 const [records,setRecords] = React.useState([]) 
 const [selectedForm, setSelectedForm] = React.useState('')
 

  function getChildObject(table){

    var uri = "https://api.fulcrumapp.com/api/v2/query?token=" + config.API_KEY + "&format=json&q=";
    var query = 'Select * from tables where parent like \'' + table + '\'';

    uri+=query;
     
    //console.log(uri);
    fetch(uri)
    .then(response => {
      if (response.ok) {
         response.json().then(data =>
         {  
         
          data.rows.map(item=> 
            { 
              Query(item.name, true)
              //console.log(item.name + "!!!!!!!!!!!!!!!!!!!!!")
              //console.log(item)
            } 
            )
         
         })
       
      } else {
        throw new Error('Something went wrong ...');
      }
    })
  }
  //record id, form type name
  function Query(what, where, isChild){
    getChildObject(what);
   
    var subTable = ''
   

    var uri = "https://api.fulcrumapp.com/api/v2/query?token=" + config.API_KEY + "&format=json&q=";
    var query = 'Select * from "' + what + '' + subTable + '" where '+ (isChild ? 'parentid' : '_record_id') +  '=\'' + where + '\'';
   //console.log(query)
    uri+=query;
    //console.log(uri);
    fetch(uri)
    .then(response => {
      if (response.ok) {
         response.json().then(data =>
          {//console.log(what)
          //console.log(data)}
          )
       
      } else {
        throw new Error('Something went wrong ...');
      }
    })
  }
  function loadRecords(formId,name){
    var options = {
      form_id: formId,
      page: 1,
      per_page: 10
    }

    client.records.all(options)
  .then((page) => {
    setSelectedForm(name)
    setRecords(page.objects);
    //console.log(page);
    
  })
  .catch((error) => {
    //console.log(error.message);
  });

  }


   
}
*/
 
  export   {getLineSeries, getForms,Query, getDailyCount,getHeatMatrix,getDistinctMetric,getDistinctMetricToArr,getPieData,getRecordsByFilter}