import { ResponsivePie } from '@nivo/pie'
import ReactCardFlip from 'react-card-flip';
import React from "react";
import BurnDown from '../../components/burndown'
import HeatMap from './heatmap'
import { ResponsiveRadar } from '@nivo/radar'
import { find } from 'p-iteration';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
 import ClearIcon from '@material-ui/icons/Clear'
 import Fab from '@material-ui/core/Fab';
import {getCCCountsByType,getPieData,getRecordsByFilter,getHeatMatrix} from '../../lib/fulcrum'
import { Paper } from '@material-ui/core';
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
 
    control: {
        padding: theme.spacing(2),
      },
    fab: {
        margin: theme.spacing(1),
      },
      extendedIcon: {
        marginRight: theme.spacing(1),
      },
  }));
export default function Pie (props){
    const classes = useStyles();

    const [selData,setSelData] = React.useState(undefined)
    const [flip,setFlip] = React.useState(false)
    const [reset,setReset] = React.useState(false)
    const [bdData,setBdData] = React.useState()
    const [origData] = React.useState(props.data); 
    const [working,setWorking] = React.useState(props.data)
    const [rdData, setrdData] = React.useState(undefined)
   async  function findInArray(arr, id){
        var i = 0;
        arr.map(item=>
            {
                console.log(item.id + "=" + id)
                if(item.id===id){
                    
                    return i;
                    
                }
                    i++;
            }
            )
        return undefined;
    }
    function rdrClick(e){

        console.log(e)
    }
    async function barFilter(e){

       let objFilter = {
            field:'site_name',
            value:e.data.site_name,
            op:'='
        }
        var newData = await getPieData("vwComplianceConcerns",'compliance_concern', objFilter)
        setWorking(newData)
        setReset(true)
        let tmp = {
            id:e.data.site_name
        }
        pieClick(tmp,null,'site_name')
        console.log(e)
    }
    function resetData(){
        setReset(false)
        setWorking(origData)
        setBdData(undefined);
    }

   async  function pieClick(e,tmp,field){
       props.loadingCB(true);
        setSelData(e)
      /*  let d = await JSON.parse(localStorage.getItem('matrix')) ;
        let i = await JSON.parse(localStorage.getItem('indexby'))
      let ix =  await JSON.parse(localStorage.getItem('index'))
      let io = { sites:d, keys :ix}
      setBdData(io);
      return;*/
      console.log(field)
      let options = [
        {
            name : field===undefined || field ===null ? 'compliance_concern' : field,
            value : e.id
        }

        ]
        var x = await getRecordsByFilter('vwComplianceConcerns', options)
     
       
        let data =  await  getHeatMatrix('vwComplianceConcerns',"site_name","compliance_concern")
        console.log(data )
        let sites = []
        let ky = [e.id];
        let status = []
        let statuskeys = ['Current Status']
       
        x.map( a=>{
            
            var done = false;
            status.map(item=>
                {
                    
                  
                    if(item.id===a._status){
                        
                        item['Current Status']+=1
                        done = true;
                    }
                       
                }
            )
            if(!done){
                let obj ={}
                obj.cc = e.id;
              
                obj.status = a._status
               obj.id = a._status
               obj['Current Status'] = 1
               //statuskeys.push(a._status)
       
                 status.push(obj)
            }
            
        })
     
    
       // setFlip(!flip)
        data.matrix.map(a=>{
           
               if(a[e.id]!==0 && a[e.id]!==null && a[e.id] !==undefined && a[e.id]!==""){
            
                  console.log(a[e.id])
                   let obj = {}
                   obj.site_name = a.site_name ;
                   obj[e.id] = a[e.id];
                   obj[e.id+'color'] = "hsl(266, 70%, 50%)";
                   
                   sites.push(obj)
               }
                
           
        })
        let xx = {
            keys:statuskeys,
            status : status
        }
      setrdData(xx);
        console.log(status)
 
        let o = {
            keys:ky,
            sites:sites   
        };
        if(field===undefined || field ===null) 
        setBdData(o);
        props.loadingCB(false);

    }

    // onClick={()=>setFlip(!flip)}
    return(
        <div style={{height:450}}>
       
 
 


 <Grid container className={classes.root} spacing={12} spacing={2}>
     <Grid item xs={12}> <div align="center"><h3>{selData && selData.id}&nbsp;</h3></div></Grid>
      <Grid item xs={3} padding={5}><Paper elevation={4}><div style={{height:450, verticalAlign:'bottom'}}> 
{bdData === undefined ? <div className="centerMsg">Please Select A Compliance Concern</div>  :
 <ResponsiveRadar
        onClick={()=>alert('clicked')}
        data={rdData.status}
        keys={rdData.keys}
        indexBy="status"
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={2}
        borderColor={{ from: 'color' }}
        gridLevels={5}
        gridShape="circular"
        gridLabelOffset={36}
        enableDots={true}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        enableDotLabel={true}
        //dotLabel="value"
        dotLabelYOffset={-12}
        colors={{ scheme: 'nivo' }}
        fillOpacity={0.40}
        blendMode="multiply"
        animate={true}
        motionStiffness={270}
        motionDamping={10}
        isInteractive={true}
        legends={[
            {
                anchor: 'top-left',
                direction: 'column',
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: '#999',
                symbolSize: 12,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
/>}</div></Paper></Grid>
    
     
    
    
    
    
    
    <Grid item xs={6} padding={1}>

 <Paper  elevation={4}>
<div align="center" id="front"key="front" style={{height:450}}>
          
    <ResponsivePie className="piePointer"
       onClick={ pieClick}
       borderColor="black"
        data={working}
        enableRadialLabels={true}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.4}
        padAngle={0.7}
        cornerRadius={3}
        colors={{ scheme: 'category10' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 2.9 ] ],   effects: [
            {
                on: 'hover',
                style: {
                    width:5,
                    color: '#000000'
                }
            }
        ] }}
        radialLabelsSkipAngle={10}
        radialLabelsTextXOffset={5}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={1}
        radialLabelsLinkDiagonalLength={20}
        radialLabelsLinkHorizontalLength={35}
        radialLabelsLinkStrokeWidth={1}
         
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
   
     
       
    />
            
            {reset &&
              <Fab color="primary" aria-label="Reset Main Data View" className={classes.fab}>
              <ClearIcon onClick={resetData} />
            </Fab>
            }
            
 </div> 
 </Paper>
  

    </Grid>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
 

     <Grid item xs={3}>    <Paper  elevation={4}><div style={{height:450}} > 
     
     {bdData === undefined ?  <div className="centerMsg">Please Select A Compliance Concern</div> :
     <HeatMap filter={barFilter} mode='bar' data={bdData && bdData.sites} index={"site_name"} keys={bdData && bdData.keys}/>
    }
     </div>     
     </Paper></Grid>
     

     </Grid>
    

 </div>

)
    }
    //