import { Fab } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    warningIcon : {color: 'rgba(252, 252, 80,99)', fontSize:24},
    panelHeader : {backgroundColor: 'rgba(255,255,255,75)', color: 'rgba(0,0,0,99)', fontSize: 18,borderRadius:'7px',},
    panelHeaderSm : {backgroundColor: 'rgba(255,255,255,75)', color: 'rgba(0,0,0,99)', fontSize: 18,borderRadius:'7px',},
    rp:{borderBottom: '1px solid rgba(0, 0, 0, .125)', minWidth: '650px', width:'100%', borderRadius:'7px',
    marginBottom: -1,
    minHeight: 25,
    '&$expanded': {
      minHeight: 25,
    },
}
  }));

  export default function Panel(props){
    const classes = useStyles();
    const panel_header = props.PanelHeader;
    const children = props.children;
    const size = props.size; //small or not
    const exp = props.expanded;
    return(
      <div width="100%">
        <ExpansionPanel  square expanded={exp} className={classes.rp} elevation={5} width="100%">
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"  className={size === "small" ? classes.panelHeaderSm : classes.panelHeader}
          >
       
           
            <Typography   className={size === "small" ? classes.panelHeaderSm : classes.panelHeader}>{panel_header.toString().substring(0,1)==='!'? <div>  {panel_header} </div>: panel_header }</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div>
           
              <table cellPadding={10}>
                <tr>
                    <td  colSpan="2">
                    {children}</td>
                </tr></table>
               
                </div>
    
          </ExpansionPanelDetails>
        </ExpansionPanel>
        </div>
    )
  }