import React from "react";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { AuthConsumer } from "./auth/context";

const Logout = () => (
  <AuthConsumer>
    {({ logout }) => (
      <Button color="inherit"   onClick={logout}>
        Logout
      </Button>
    )}
  </AuthConsumer>
);

export default Logout;