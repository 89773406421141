import React from "react";
import Button from '@material-ui/core/Button';
import Auth from './auth/auth'
import { AuthConsumer} from "./auth/context";
 
const Login = () => (
  <AuthConsumer>
    {({login} ) => (
       
      <Button color="inherit" onClick={login}   >
       Login
      </Button>
     
      
    )}
  </AuthConsumer>
);

export default Login;