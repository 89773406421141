import { ResponsiveLine } from '@nivo/line'
import React from 'react'
function SmallLine(props){


return(
    <ResponsiveLine
  
    data={props.data}
    margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
    xScale={{ type: 'point' }}
    yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
    axisTop={false}
    axisRight={false}
    
    axisLeft={false
    }
    axisBottom={false}
    enableArea={true}
    areaOpacity={0.2}
    enablePoints={false}
    colors={{ scheme: 'nivo' }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabel={true}
    pointLabelYOffset={-12}
    
    useMesh={true}
    enableGridX={true}
    enableGridY={true}
    isInteractive={true}
    
/>


)

}

export {SmallLine}