import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


/*Side bar + shared components */
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
 
import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
 

 
export default class DailyReports extends React.Component {
  state = {
    activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
    controlledPosition: {
      x: -400, y: 200
    }
  };
      eventLogger = (e , data ) => {
      console.log('Event: ', e);
      console.log('Data: ', data);
    };

    handleDrag = (e, ui) => {
      const {x, y} = this.state.deltaPosition;
      this.setState({
        deltaPosition: {
          x: x + ui.deltaX,
          y: y + ui.deltaY,
        }
      });
    };
  
    onStart = () => {
      this.setState({activeDrags: ++this.state.activeDrags});
    };
  
    onStop = () => {
      this.setState({activeDrags: --this.state.activeDrags});
    };
  
    // For controlled component
    adjustXPos = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const {x, y} = this.state.controlledPosition;
      this.setState({controlledPosition: {x: x - 10, y}});
    };
  
    adjustYPos = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const {controlledPosition} = this.state;
      const {x, y} = controlledPosition;
      this.setState({controlledPosition: {x, y: y - 10}});
    };
  
    onControlledDrag = (e, position) => {
      const {x, y} = position;
      this.setState({controlledPosition: {x, y}});
    };
  
    onControlledDragStop = (e, position) => {
      this.onControlledDrag(e, position);
      this.onStop();
    };
    render(){
      const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition, controlledPosition} = this.state;
    return(
            <React.Fragment >
                <div id="main-content">
                <Draggable
        axis="x"
        handle=".handle"
        defaultPosition={{x: 200, y: 200}}
        position={null}
        grid={[25, 25]}
        scale={1}
        position={controlledPosition} {...dragHandlers} onDrag={this.onControlledDrag}>
          <div border={3}>
                 khakjaskhdashdkjahsjkddhakjsashdjkahskjdssdkhakjsshdjkashkjdhaskjshdkjahskjhsdkjhasjkddh
                </div>
            </Draggable>
            </div>
            </React.Fragment>


    )
    }
}