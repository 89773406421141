import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { SmallLine } from "./components/charting/line";
import NavBar from "./components/Navbar";
import config from "./config";
import { getDailyCount, getPieData } from "./lib/fulcrum";
import { getBasicMapData, getCCByDay, getCCCounts, getCriticalCCCount, getQCCount, getSummaryMetrics, getUniList } from './lib/fulcrumhst';
import CallbackPage from "./pages/callback";
import DailyReports from './pages/dailyReports';
import HomePage from "./pages/home";
import HSTDash from "./pages/HSTDash";
import LogIssue from './pages/logIssue';



const useStyles = makeStyles({
  root: {
    width: 500,
  },
});
 
function App() {
 
   const [flags,setFlags] = React.useState({
    loading:false,
    requiresUpdate:false,
  })
   const classes = useStyles();
  const [forms,setForms] = React.useState(undefined)
  const [loading,setLoading] = React.useState(flags.loading)
  const [calData,setCalData] = React.useState([])
  const [hmData,setHm] = React.useState([])
  const [hmNdx,setHmNdx] = React.useState([])
  const [hmKeys,setHmKeys] = React.useState([])
  const [pieData,setPieData] = React.useState([])
  const [bubble,setBubble] = React.useState([])
  const [counts,setCounts] = React.useState([])
  const [value, setValue] = React.useState(0);
  const [selDate, setSelDate] = React.useState(undefined)
  const [cardData, setCardData] = React.useState(undefined)
  const [summaryCards, setSummaryCards] = React.useState(undefined)
  const [cardData2, setCardData2] = React.useState(undefined)
  const [summaryCards2, setSummaryCards2] = React.useState(undefined)
  const [use,setUse] = React.useState(false)
  const [filter,setFilter] = React.useState(undefined)
  const [prj,setPrj] = React.useState(undefined)
  const [qryOpt,setQryOpt] = React.useState(undefined)
  const [mapData,setMapData] = React.useState(undefined)
  useEffect( () => {
    async  function getData(){
      getMapData();
       getDataForBubble();
       getCardData();
       getQueryOptions();
       document.title = config.title
       setProject('vwComplianceConcerns')
      // checkForUpdates();
      console.log('checking')
       if(selDate!==undefined){
         alert(selDate)
       }
    }
    if(!use){
    getData();
      setUse(true)
    }
 
  },[use,setUse])
    function cb_forms(frms){
      setLoading(false)
       setForms(frms)
  }

async function getMapData(){
  var dt = await getBasicMapData();
  setMapData(dt)
  console.log(dt)
}
 async function getQueryOptions(){
  await changeLoad()
   let siteData = await   getUniList('site_name') ;
   let statu = await getUniList('_status');
   let groups = await getUniList('compliance_concern_group');
   let ratings = await getUniList('Concern_Rating','vwComplianceConcerns');
    let status = await getUniList('_status','High Speed Train Daily Monitoring')
    let people = await getUniList('field_person','High Speed Train Daily Monitoring')
  let queryCriteria_cc ={ 
    name : 'cc',
    table: 'vwComplianceConcerns',
    title : 'Compliance Concerns',
    from: ['Site Name','Concern Status','Concern Group','Concern Rating'],
    fields: ['site_name','_status','compliance_concern_group', 'Concern_Rating'],
    opers : ['equal to','is not equal to','is empty','is not empty'],
    relatedOptions : [{'Site Name' : siteData},{'Concern Status':statu},{'Concern Group':groups}, {'Concern Rating':ratings}]
      }
  let queryCriteria_daily = {
    name: 'daily',
    title: 'Daily Monitoring',
    table: 'High Speed Train Daily Monitoring',
    from: ['Field Person','Report Status','Contained Site'],
    fields : ['field_person','_status','site_reports'],
    opers : ['equal to','is not equal to','is empty','is not empty'],
    relatedOptions : [{'Field Person' : people},{'Report Status':status} ,{'Contained Site' : siteData}]
    
  }
  var queryCriteria =   [queryCriteria_cc,queryCriteria_daily];
  
     await setQryOpt(queryCriteria)
     await changeLoad()
  }
  function requestFilter(filter){
   alert(filter)
  }
  function checkForUpdates(){
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      checkForUpdates();
    }, 30000);
  }
  function getItem(obj,item){
 
  }
  async function getCardData(){
    await changeLoad()
    var data = await getItem('cards_LocCounts')
    if(data===undefined || data===null ){
      
      var x = await getCCCounts();
      await store('cards_LocCounts', x)
      data = x;
    }
      let cards = [];
      data.map(a=>
        {
        
        let detail = {
          title:a.concern,
          mid: (<h1>  {a.concern}  </h1>)
        }
          let objCardData ={
          title:(<span>Open: {a.opencount}  Historical: {a.totalcount} </span>),
          number: a.opencount,
          onFire : a.opencount > 10,
          text : a.concern,
          avatar : 'C',
          detail:detail
        }
        cards.push(objCardData)
      }
        )
    if(summaryCards!==undefined)
      return;
    let cd_line = await getCCByDay('line');
    let sum = await getSummaryMetrics();
    console.log(sum);
        let sCards = []
    let cd = {
      title:'New Concerns!',
      number: sum[0].newc,
      onFire : true,
      text : 'Compliance concerns that have been identified in the past 3 days',
      avatar : 'C',
      canFlip : true,
      canFilter: true,
      filter: 'new',
      filtered: filter==='new',
      back : (<div style={{height:220,width:'100%'}}><SmallLine xLabel="Time" yLabel="# of CC" data={cd_line}/></div>)
    }
    sCards.push(cd);
    let cd1 = {
      title:'Open Concerns',
      number:  sum[0].openc,
      onFire : false,
      text : 'Compliance concerns that are currently in resolution',
      avatar : 'C',
      canFlip : false,
      canFilter: true,
      filter: 'open',
      filtered: filter==='open'
    }
    sCards.push(cd1); 
    let drCount = await getQCCount();
    let cd2 = {
      title:'Daily Reports in QA',
      number:  drCount,
      onFire : false,
      text : 'Daily Reports that are currently in QA',
      avatar : 'C',
    }
    sCards.push(cd2); 
    console.log(filter)
    let cd3 = {
      title:'Concerns to date',
      number:  sum[0].totc,
      onFire : false,
      text : 'The total number of concerns in the database',
      avatar : 'C',
      canFilter: true,
      filter: 'none',
      filtered:  filter==='none'
    }
    sCards.push(cd3); 
    let xCount = await getCriticalCCCount();
    let cd4 = {
      title:'Critical Count',
      number: xCount,
      onFire : false,
      text : (<>Count of active critical compliance concerns.<br></br></>),
      avatar : 'C',
      canFilter: true,
      filter: 'critical',
      filtered: filter==='critical'


    }
    sCards.push(cd4); 
    let cd5 = {
      title:'Most active site',
      number:  sum[0].sitec,
      onFire : false,
      text : 'The count of the most active concerns on a single site ',
      avatar : 'C',
    }
    sCards.push(cd5); 
    setCardData(cards)
    setSummaryCards(sCards)
    let sCards_1 = []
    let cd_1 = {
      title:'Resolution Cadence',
      number: 6.1,
      onFire : false,
      text : 'The average  (in days) it takes to resolve a compliance conern',
      avatar : 'C',
      canFlip : true,
      canFilter: true,
      filter: 'new',
      filtered: filter==='new',
      back : (<div style={{height:220,width:250}}><SmallLine xLabel="Time" yLabel="# of CC" data={cd_line}/></div>)
    }
    sCards_1.push(cd_1);
    let cd_11 = {
      title:'Avg Daily New',
      number:  2.53,
      onFire : false,
      text : 'The average number of compliance concerns logged per day',
      avatar : 'C',
      canFlip : true,
      canFilter: true,
      filter: 'open',
      filtered: filter==='open'
    }
    sCards_1.push(cd_11); 
    let cd_12 = {
      title:'Days to Emma',
      number:  '1.5',
      onFire : true,
      text : 'The average time it is taking for a field logged daily report to be posted to Emma',
      avatar : 'C',
    }
    sCards_1.push(cd_12); 
    console.log(filter)
    let cd_13 = {
      title:'Avg CC per Site ',
      number:  1.1,
      onFire : false,
      text : 'The average number of compliance concerns per site',
      avatar : 'C',
      canFilter: true,
      filter: 'none',
      filtered:  filter==='none'
    }
    sCards_1.push(cd_13); 
    let cd_14 = {
      title:'Over 60 days?',
      number: '23',
      onFire : false,
      text : 'The number of compliance concerns that are still pending after 60 days',
      avatar : 'C',
    }
    sCards_1.push(cd_14); 
    let cd_15 = {
      title:'Most CC/Day',
      number:  17,
      onFire : false,
      text : 'The most compliance concerns captured in a single day ',
      avatar : 'C',
    }
    sCards_1.push(cd_15); 
    console.log(sCards_1)
    setSummaryCards2(sCards_1)
    await changeLoad()
  }
  function getFilter(filterArg){
    var filter = {
      'new' : function () {
        return  {field:'_status',value:'New',op:'='}
      },
      'open' : function(){
        return {field:'_status',value:'Resolved',op:'<>'}
      },
      'none' : function(){
        return {field:'none',value:'Resolved',op:'<>'}
      },
      'critical' : function(){
        return {field:'compliance_concern', value:'!%',op:'like'}
      }
    }
    return filter[filterArg]();
  }
  async function acceptFilter(filterArg){
    await changeLoad()
    setLoading(true)
    console.log('App :: Filter')
    
    let filt = getFilter(filterArg)
    await setProject(undefined,filt);
    await changeLoad()
  }
  async function setHmData(data, index, keys){
    setHm(data);
    setHmNdx(index)
    setHmKeys(keys);
  }
  async function getDataForBubble(){
    const resp= await fetch('https://gistest02.azurewebsites.net/api/Strategies/2');
    const x = await resp.json() 
    console.log(x)
    x.children = x.projections;
    x.children.map(p=>{
      p.id = p.name
   
    p.performanceTargets.map(a=>{
  
      a.cumlativeTotals.map(b=>{
        b.id =  b.year + ' ' + b.value
      })
      a.id = a.name
      a.children = a.cumlativeTotals
    })
    p.version = 0.3
    p.children = p.performanceTargets
  })
    await setBubble(x);
  }
  async function store(name,obj){
   
    localStorage.setItem(name,JSON.stringify(obj)) ;
  }
  async function getItem(name){
    
   return JSON.parse(localStorage.getItem(name)) ;
  }
  async function setProject(prjC, filt){
      if(prjC!==undefined){
      await setPrj(prjC)
     

      }
      console.log(prj)
      if(filter!==undefined || filt !== undefined)
    {console.log(filter)
      console.log(filt)
    }
      
   
      var p = await getPieData(prjC ===undefined ? prj : prjC,"compliance_concern",filt === undefined ? filter === undefined? undefined : filter : filt)
      setPieData(p);
         var data = await getDailyCount(prjC ===undefined ? prj : prjC);
        await setCalData(data);
        
   //  let   d =  await  getHeatMatrix(prjC ===undefined ? prj : prjC,"site_name","compliance_concern")
        var counts = await getCCCounts();
         
        setCounts(counts)
   
  
   
  }
  async function changeLoad(){
    console.log(forms)
    var tmpFlags = flags;
   await setLoading( !flags.loading)
    tmpFlags.loading = !flags.loading;
    await setFlags(tmpFlags);
    console.log(flags);
  }
  function NavBarMsgs(msgs){

  }
  return (

    <div className="App container">
 {/*}
  <AuthConsumer>
  { ({user, login}, isAuthenticated) => (
  <>
   {console.log(user.displayName)}
  */}
      <div className="jumbotron">
          
        <BrowserRouter>
        
 
        <NavBar SiteFlags={flags} forms={forms} msgs={NavBarMsgs} cbForms={cb_forms} cbProject={setProject} cbHm={setHmData}/>
          <Switch>
      
            <Route exact path="/" component={()=> <HomePage cardData={summaryCards} CardData={cardData} Bubble={bubble} SiteFlags={flags} forms={forms}/> } />




          
          {// <Route path="/dashboard" exact component={()=><DashboardPage filterCB={requestFilter} cb={setSelDate} counts={counts} calData={calData} hmData={hmData} hmKeys={hmKeys} hmNdx={hmNdx} pieData={pieData}/>}/>
          } 
          <Route path="/newReports" component={DailyReports}/>
            <Route path="/callback"   component={CallbackPage}/>
            <Route path="/issue"  component={()=><LogIssue/>}/>
            <Route path="/hst-dash"   component={()=><HSTDash mapData={mapData} queryOptions={qryOpt} cards2={summaryCards2} cards={summaryCards} filter={acceptFilter}  filterCB={requestFilter} cb={setSelDate}  counts={counts} calData={calData} hmData={hmData} hmKeys={hmKeys} hmNdx={hmNdx} piData={pieData}/>}/>
          </Switch>
        
        </BrowserRouter>
      </div>  {/*} </> 
    
      
  }
    
      </AuthConsumer>  )*/}
     
  </div>
   
 
    
     
    
   
  );
}
 

export default App;