import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { getCC } from '../../lib/fulcrumhst';
import Panel from '../panel';
import { Paper } from '@material-ui/core';
 
const useStyles = makeStyles(theme => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: 500,
      height: 450,
    },
    warningIcon : {color: 'rgba(252, 252, 3,80)', fontSize:18}
  }));
function ComplianceConern(props){
    const classes = useStyles();
const [data,setData] = React.useState();
const [ccId, setccId] = React.useState(props.ccid);
const [dls,setDls] = React.useState();
const [qTrig, setQTrig] = React.useState(true);
const size = props.size;
const [skipPanel,setSkipPanel] = React.useState(props.noPanel);
 
const exp = props.exp;
useEffect(() => {
    if(qTrig){
        loadData()
          setQTrig(false)
    }
  });
    async function loadData(){
    console.log(ccId);
        if(ccId===null || ccId===undefined)
            return;
        var objData = await getCC(ccId);
        if(objData===null){
            console.log("No data was returned for CC = " + ccId)
            return;
        }
        setData(objData.cc);
        setDls(objData.dls);
     
    }

return(

  <>
 
 {props.noPanel ? 

          <div>
        

        {data && (

           <div width="100%" align="center">
           <table className={props.unrestrictedWidth ? 'cc_table_ur' : 'cc_table'} width="100%" border="0"   >
                <tr>
                  <td colSpan="2">    <h2>{data[0]['compliance_concern']}  </h2></td>
                </tr>
                  <tr>
                        <td colSpan="2" className={data[0]['compliance_concern'].toString().substring(0,1) !== '!' ?  "details_header" : "critical"}>   </td>
                        
                    </tr>
                    <tr>
                        <td colSpan="details_field">Date First Observed : </td><td className="details_field_text">{data[0]['_created_at']}</td>
                    </tr>
              
                    {dls&&
                        <tr>
                            <td colSpan="2" align="center">
                              
                                        
                                    {dls.map(log => (
                                        log.photos.split(',').map(photo=>
                                            <div>{log.notes + " (" + log.date.split(' ')[0] + " : " + log.time + ")"}<br></br>
                                        <a href={'https://esagisdev.azurewebsites.net/api/FulcrumPhotos/' + photo  } target="_new">  <img className="cc_Image" src={'https://esagisdev.azurewebsites.net/api/FulcrumPhotos/' + photo } width="100%"/></a> </div>
                                        )
                                    ))}
                          
                            </td>
                        </tr>
                    }
                </table></div> 
        )}

          </div>
            :
            data && (
                
                <Panel PanelHeader={data[0]['compliance_concern']} size='small' expanded={exp} unrestrictedWidth={props.unrestrictedWidth} >
            
                <table className="cc_table" width="100%" border="0" >
                <tr>
                        <td colSpan="details_field">Date First Observed : </td><td className="details_field_text">{data[0]['_created_at']}</td>
                    </tr>
                  <tr>
                        <td colSpan="2" className={data[0]['compliance_concern'].toString().substring(0,1) !== '!' ?  "details_header" : "critical"}>   </td>
                        
                    </tr>
          
              
                    {dls&&
                        <tr>
                            <td colSpan="2" align="center">
                              
                                        
                                    {dls.map(log => (
                                        log.photos.split(',').map(photo=>
                                            <div>{log.notes + "(" + log.date.split(' ')[0] + " : " + log.time + ")"}<br></br>
                                        <a href={'https://esagisdev.azurewebsites.net/api/FulcrumPhotos/' + photo} target="_new">  <img className="cc_Image" src={'https://esagisdev.azurewebsites.net/api/FulcrumPhotos/' + photo} width="100%"/></a> </div>
                                        )
                                    ))}
                          
                            </td>
                        </tr>
                    }
                </table>
              </Panel>
            )}
</>
)
                  
    
 
}

export default ComplianceConern;
