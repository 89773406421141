import React, {PureComponent} from 'react';
import {Marker} from 'react-map-gl';
import { FontAwesomeIcon } from  '@fortawesome/react-fontawesome'
import {faCircle} from '@fortawesome/free-solid-svg-icons'
import GroupIcon from '../hst/ccGroupIcon';
import { Fab } from '@material-ui/core';
const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const SIZE = 50;

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export default class Pins extends PureComponent {
  render() {
     
    const {data, onClick, icoSize} = this.props;
 
    return  <> {data && (data.map((cc, index) => (
      <Marker key={`marker-${index}`} longitude={parseFloat(cc.longitude)} latitude={parseFloat(cc.latitude)}>
          <Fab   onClick={() => onClick(cc)}  style={{
            cursor: 'pointer',
           fill: 'rgba(255,255,255,0.5)',
           backgroundColor : 'rgba(255,255,255,0.75)', fontSize:{icoSize},
           color: 'rgba(255,10,50,0.95)',
            stroke: 'none',
            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
          }} size="small">
      {/*  <svg
          height={SIZE}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
           fill: 'rgba(255,255,255,0.5)',
           color: 'rgba(255,10,50,0.95)',
            stroke: 'none',
            transform: `translate(${-SIZE / 2}px,${-SIZE}px)`
          }}
          onClick={() => onClick(cc)}
        > </svg>*/}

<GroupIcon group={cc.grp} className="ccGroupIcon"   style={{color:'rgba(0,10,50,0.95)'}} />
        
       </Fab>
       
      </Marker>
    ))
    )}</>
  }
}