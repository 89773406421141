import {
  Button,
  Chip,
  Container,
  Drawer,
  Input,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Zoom from "@material-ui/core/Zoom";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Document, Page, pdfjs } from "react-pdf";
import { withRouter } from "react-router-dom";
import { RotateSpinner } from "react-spinners-kit";
import config from "../config";
import { Query as qry } from "../lib/fulcrum";
import { getdm } from "../lib/fulcrumhst";
import { AuthConsumer } from "./auth/context";

const initialState = {
  mouseX: null,
  mouseY: null,
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
String.prototype.replaceAll = function (str1, str2, ignore) {
  return this.replace(
    new RegExp(
      str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"),
      ignore ? "gi" : "g"
    ),
    typeof str2 == "string" ? str2.replace(/\$/g, "$$$$") : str2
  );
};
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    display: "flex",
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  fullList: {
    width: 300,
  },
  container: {
    maxHeight: 900,
    scrollbarColor: "#ff00ff",
  },
  tblCont: {
    verticalAlign: "top",
  },
  tblText: {
    fontSize: 12,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  zoom: { fontSize: 32 },
  table: {},

  control: {
    padding: theme.spacing(2),
  },
  Tooltip: {
    fontSize: 12,
  },
  title: {},
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const rowDiv = 50;
const store = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [] };
const displayFields = config.report_DisplayFields;
function Reports(props) {
  const [msgOpen, setMsgOpen] = React.useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [, setValues] = React.useState({});
  const [selQry] = React.useState(1);
  const [data, setData] = React.useState(undefined);
  const [, setCols] = React.useState(undefined);
  const [qTrig, setQTrig] = React.useState(true);
  const [] = React.useState(true);
  const [monid, setMonId] = React.useState(0);
  const [, setRpt] = React.useState();
  const [] = React.useState();
  const [key, setKey] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    parseInt((window.innerHeight - 400) / rowDiv, 10)
  );
  const [doc, setDoc] = React.useState(undefined);
  const [showbutt, setShowButt] = React.useState(false);
  const [pdfPage, setPdfPage] = React.useState(1);
  const [pdfPages, setPdfPages] = React.useState(1);
  const [multiStore, setMultiStore] = React.useState(store); //Array of objects {string, array} id,selectedValues
  const [startDate, setStartDate] = React.useState(new Date().addDays(-365));
  const [endDate, setEndDate] = React.useState(new Date());
  const [pdfScale] = React.useState(1.25);
  const [pdfHeight, setPdfHeight] = React.useState(
    parseInt(window.innerHeight - 400)
  );
  const open = Boolean(anchorEl);
  const [xopen, setxOpen] = React.useState(false);
  const [fopen, setFOpen] = React.useState(false);
  const [state, setState] = React.useState(initialState);
  const [canFinal, setCanFinal] = React.useState(false);
  const [isFinal, setIsFinal] = React.useState(false);
  const [contextFilter] = React.useState();
  const [contextFilterUse, setContextFilterUse] = React.useState();
  const m_handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const msg_handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setMsgOpen(false);
  };

  const m_handleClose = () => {
    setState(initialState);
  };
  useEffect(() => {
    if (qTrig) {
      doQuery(false);
      setQTrig(false);

      //   props.history.push("/hst-dash/dm");
      //console.log('triggered!')
      window.addEventListener("resize", windowResized);
    }
  });
  function windowResized() {
    setRowsPerPage(parseInt((window.innerHeight - 400) / rowDiv, 10));
    setPdfHeight(parseInt(window.innerHeight - 400));
  }
  async function selectReport(id) {
    if (monid !== id) {
      setMonId(id);
      loadData(id);
    }
  }
  async function finalizeRecord() {
    var _qry =
      'UPDATE "High Speed Train Daily Monitoring" SET flg_final = 1 WHERE _record_id=\'' +
      key +
      "'  ;";
    try {
      await qry(_qry);
    } catch {
      //Meh
    }
    await doQuery();
  }
  async function deFinal() {
    var _qry =
      'UPDATE "High Speed Train Daily Monitoring" SET flg_final = 0 WHERE _record_id=\'' +
      key +
      "'  ;";
    try {
      await qry(_qry);
    } catch {
      //Meh
    }
    await doQuery();
  }
  async function HandleChangeDynaMulti(event) {
    let mStoreTmp = multiStore;
    mStoreTmp[event.target.name] = event.target.value;
    await setMultiStore(mStoreTmp);
    await handleChange(event);

    /* 
     mStore[event.target.name] = event.target.value;
      let obj = multiStore.find(o=> o.control === event.target.name);
      setSelected(event.target.value);
      //console.log(event.target)
      //console.log(obj);
      if(obj!==undefined){
        const { options }v = event.target;
        
       
        obj.selectedValues = event.target.value;
      }else{
  
      }*/
  }
  function clearFilters() {
    localStorage.removeItem("cc_Query");
    setMultiStore({
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
    });

    setValues({});
    doQuery(true);
    setPage(0);
    setFOpen(false);
  }
  async function handledateEndChange(date) {
    setEndDate(date);
    await doQuery(false);
  }
  async function handleChange(event) {
    //console.log(event)

    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));

    // setQTrig(true)
  }
  async function handledateChange(date) {
    setStartDate(date);
    await doQuery(false);
  }
  const getStartDate = startDate;
  const getEndDate = endDate;
  function movePage(i) {
    if (i < 0 && pdfPage === 1) return;
    setPdfPage(pdfPage + i);
  }
  async function doSort(col) {
    col = `[${col}]`;
    let _ob = localStorage.getItem("dr_Order");
    if (_ob === col) col += " desc";
    localStorage.setItem("dr_Order", col);
    doQuery(false);
  }
  async function emailReport(pEmail) {
    try {
      await fetch(
        config.api_base +
          "/reports/EmailReport?rptId=" +
          monid +
          "&emailAddr=" +
          pEmail
      );
    } catch (err) {}

    setMsgOpen(true);
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function setPages(pages) {
    setPdfPages(pages.numPages);
    setPdfPage(1);
    setShowButt(true);
    //console.log(pages);
  }
  function getAltText(text) {
    let obj = config.textSubs.find((a) => a.org === text);
    if (obj !== undefined) return obj.value;
    return "";
  }
  async function loadData(Dmid) {
    props.loadingCB(true);
    // setKey(0);
    setShowButt(false);
    setDoc(config.api_base + "reports/" + Dmid);
    //console.log('getting daily data');
    if (Dmid === null || Dmid === undefined) return;
    var objData = await getdm(Dmid);
    if (objData === null || objData === undefined) return;
    if (objData.rows.length === 0) {
      alert("no data found" + Dmid);
      return;
    }
    //console.log(objData)
    setKey(objData.rows[0]["_record_id"]);
    setRpt(objData);
    props.loadingCB(false);
    //  setSite(objData[0]['site_reports'].split(','));
  }

  async function doQuery(clear) {
    props.loadingCB(true);
    //console.log('Calling???')
    let _ob = localStorage.getItem("dr_Order");
    var _qry =
      "SELECT site_reports, " + displayFields.join(",") + " FROM vwDaily ";
    _qry +=
      " WHERE dtDate >= '" +
      getStartDate
        .toLocaleDateString("en-US")
        .replace(new RegExp("/", "g"), "-") +
      "' AND dtDate <='" +
      getEndDate
        .toLocaleDateString("en-US")
        .replace(new RegExp("/", "g"), "-") +
      "'";

    if (!clear) {
      if (multiStore[0].length > 0)
        _qry += " AND field_person in ('" + multiStore[0].join("','") + "') ";
      if (multiStore[1].length > 0)
        _qry += " AND _status in ('" + multiStore[1].join("','") + "') ";
      if (multiStore[2].length > 0)
        multiStore[2].map(
          () => (_qry += "") //Location search is not functional
        );
      if (contextFilterUse !== undefined) {
        _qry +=
          " AND " + contextFilterUse.replace("Field Biologist", "field_person");
      }
    }

    if (_ob !== undefined && _ob !== null) _qry += " order by " + _ob + "";

    //date field is actually a string due to format requirements. Should be changed/fixed
    _qry = _qry.replace("[date]", "dtDate"); //specific order clause
    let ret = await qry(_qry);
    //console.log(multiStore);

    let colsx = [];
    await ret.fields.map((a) => {
      if (displayFields.indexOf(a.name) > -1) {
        colsx.push({ key: a, name: a });
      }
    });

    setFOpen(false);
    //console.log(ret);
    setData(ret);
    setCols(colsx);
    props.loadingCB(false);
  }
  return (
    <TableContainer>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={msgOpen}
        autoHideDuration={6000}
        onClose={msg_handleClose}
        message="Report sent!"
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={msg_handleClose}>
              OK
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={msg_handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />

      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.tblCont}>
              <div>
                {data && (
                  <div>
                    <input
                      className={classes.input}
                      id="contained-filter"
                      multiple
                      type="button"
                    />
                    <label htmlFor="contained-filter">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => setFOpen(true)}
                      >
                        Show Filters
                      </Button>
                    </label>
                    <Drawer
                      anchor="right"
                      zIndex="1000"
                      open={fopen}
                      className={classes.fullList}
                      variant="persistent"
                      PaperProps={{ style: { position: "absolute" } }}
                      BackdropProps={{ style: { position: "absolute" } }}
                      ModalProps={{
                        container: document.getElementById("main-content"),
                        style: {
                          position: "absolute",
                          backgroundColor: "transparent",
                          background: "transparent",
                        },
                      }}
                    >
                      <div>
                        <Button onClick={() => setFOpen(false)}>
                          Hide Filters
                        </Button>
                      </div>
                      {props.queryOptions &&
                        props.queryOptions[selQry].from.map((item, index) => (
                          <div>
                            {" "}
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor={item}>{item}</InputLabel>
                              <Select
                                multiple
                                value={multiStore[index]}
                                onChange={HandleChangeDynaMulti}
                                minWidth="150"
                                name={index}
                                id={item}
                                input={
                                  <Input id={item + "_select-multiple-chip"} />
                                }
                                renderValue={(selected) => (
                                  <div className={classes.chips}>
                                    {selected.map((value) => (
                                      <Chip
                                        key={value}
                                        label={value}
                                        className={classes.chip}
                                      />
                                    ))}
                                  </div>
                                )}
                              >
                                {props.queryOptions[selQry].relatedOptions[
                                  index
                                ][item] &&
                                  props.queryOptions[selQry].relatedOptions[
                                    index
                                  ][item].map((a) => (
                                    <MenuItem value={a}>{a}</MenuItem>
                                  ))}
                              </Select>{" "}
                            </FormControl>
                          </div>
                        ))}
                      <div>
                        <FormControl className={classes.formControl}>
                          <DatePicker
                            todayButton
                            customInput={
                              <TextField
                                name="startDate"
                                id="startDate"
                                label="Start Date"
                              />
                            }
                            selected={startDate}
                            onChange={handledateChange}
                            startDate={startDate}
                            endDate={endDate}
                          />
                        </FormControl>
                      </div>
                      <div>
                        <FormControl className={classes.formControl}>
                          <DatePicker
                            todayButton
                            customInput={
                              <TextField
                                label="End Date"
                                name="endDate"
                                id="endDate"
                                minWidth="10"
                              />
                            }
                            selected={endDate}
                            onChange={handledateEndChange}
                            startDate={startDate}
                            endDate={endDate}
                          />{" "}
                        </FormControl>
                      </div>
                      <div>
                        <FormControl className={classes.formControl}>
                          {" "}
                          <Button color="primary" onClick={() => doQuery()}>
                            Filter
                          </Button>
                        </FormControl>
                      </div>
                      <div>
                        <FormControl className={classes.formControl}>
                          {" "}
                          <Button
                            color="primary"
                            onClick={() => clearFilters()}
                          >
                            Clear Filters
                          </Button>
                        </FormControl>
                      </div>
                    </Drawer>

                    <Paper className="dailyTable" position="static">
                      <AppBar position="static">
                        <Typography variant="h6" className={classes.title}>
                          Daily Monitoring Reports
                        </Typography>
                      </AppBar>

                      <TableContainer className="dailyTable">
                        <Menu
                          open={state.mouseY !== null}
                          onClose={m_handleClose}
                          anchorReference="anchorPosition"
                          anchorPosition={
                            state.mouseY !== null && state.mouseX !== null
                              ? { top: state.mouseY, left: state.mouseX }
                              : undefined
                          }
                        >
                          {isFinal && (
                            <MenuItem
                              disabled={!canFinal}
                              onClick={function () {
                                m_handleClose();
                                deFinal();
                              }}
                            >
                              <img
                                className="DownloadButton"
                                src="http://icons.iconarchive.com/icons/custom-icon-design/flatastic-9/512/Undo-icon.png"
                              />{" "}
                              &nbsp;Undo Final
                            </MenuItem>
                          )}
                          {/*
        <MenuItem onClick={async function(){ await doContQuery();m_handleClose(); }}><img className="DownloadButton" src="http://icons.iconarchive.com/icons/fatcow/farm-fresh/32/filter-add-icon.png"/> &nbsp;Filter for : {contextFilter}</MenuItem>
        */}
                          {!isFinal && (
                            <MenuItem
                              disabled={!canFinal}
                              onClick={function () {
                                m_handleClose();
                                finalizeRecord();
                              }}
                            >
                              <img
                                className="DownloadButton"
                                src="http://icons.iconarchive.com/icons/graphicloads/100-flat-2/128/ok-icon.png"
                              />{" "}
                              &nbsp;Mark record as final
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={
                              (m_handleClose,
                              () =>
                                window.open(
                                  config.api_base + "reports/" + monid
                                ))
                            }
                          >
                            <img
                              className="DownloadButton"
                              src="http://icons.iconarchive.com/icons/dtafalonso/android-lollipop/512/Downloads-icon.png"
                              size="12"
                            />
                            &nbsp; <ListItemText primary="Download Report" />
                          </MenuItem>

                          <MenuItem
                            onClick={
                              (m_handleClose,
                              () =>
                                window.open(
                                  "https://web.fulcrumapp.com/records/" + key
                                ))
                            }
                          >
                            <img
                              className="DownloadButton"
                              src="https://learn.fulcrumapp.com/img/branding/fulcrum-icon.png"
                            />{" "}
                            &nbsp;Open Record in Fulcrum
                          </MenuItem>

                          <AuthConsumer>
                            {(data) => (
                              <MenuItem
                                onClick={function () {
                                  setMsgOpen(true);
                                  m_handleClose();
                                  emailReport(data.user.mail);
                                }}
                              >
                                <img
                                  className="DownloadButton"
                                  src="http://icons.iconarchive.com/icons/wwalczyszyn/android-style-honeycomb/256/Mail-icon.png"
                                />{" "}
                                &nbsp; Email report to {data.user.mail}
                              </MenuItem>
                            )}
                          </AuthConsumer>
                        </Menu>

                        <Table stickyHeader className="dailyTable">
                          <TableHead>
                            <TableRow>
                              {data.fields.map((a) =>
                                a.name.charAt(0) === "_" ? (
                                  " "
                                ) : (
                                  <TableCell
                                    className="queryHeader"
                                    onClick={() => doSort(a.name)}
                                  >
                                    {a.name.charAt(0).toUpperCase() +
                                      a.name.replaceAll("_", " ").slice(1)}
                                  </TableCell>
                                )
                              )}

                              {/*  <TableCell className="queryHeader">Functions</TableCell>*/}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.rows
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map((a) => (
                                <TableRow
                                  onMouseOver={function () {
                                    setCanFinal(
                                      a["Status"].toString() == "QCC"
                                    );
                                    setIsFinal(a["__final"] === "True");
                                  }}
                                  onContextMenu={m_handleClick}
                                  style={{ cursor: "context-menu" }}
                                  className={
                                    monid === a["_record_id"]
                                      ? "queryRowSel"
                                      : a["__final"] === "True"
                                      ? "queryRowFinal"
                                      : "queryRow"
                                  }
                                  onClick={async () =>
                                    await selectReport(a["_record_id"])
                                  }
                                >
                                  {data.fields.map((b) =>
                                    b.name.charAt(0) === "_" &&
                                    b.name.charAt(0) !== "__esa_id" ? (
                                      " "
                                    ) : (
                                      <TableCell
                                        className={
                                          monid === a["_record_id"]
                                            ? "queryRowSel"
                                            : a["__final"] === "True"
                                            ? "queryRowFinal"
                                            : "queryRow"
                                        }
                                      >
                                        <Tooltip
                                          title={
                                            getAltText(a[b.name]) && (
                                              <span className={classes.Tooltip}>
                                                {getAltText(a[b.name])}
                                              </span>
                                            )
                                          }
                                          arrow
                                          TransitionComponent={Zoom}
                                        >
                                          {b.name === "Status" &&
                                          a["__final"] === "True" ? (
                                            <div
                                              className={
                                                monid === a["_record_id"]
                                                  ? "queryRowSel"
                                                  : a["__final"] === "True"
                                                  ? "queryRowFinal"
                                                  : "queryRow"
                                              }
                                            >
                                              {" "}
                                              Finalized
                                            </div>
                                          ) : (
                                            <div
                                              className={
                                                monid === a["_record_id"]
                                                  ? "queryRowSel"
                                                  : a["__final"] === "True"
                                                  ? "queryRowFinal"
                                                  : "queryRow"
                                              }
                                            >
                                              {" "}
                                              {a[b.name] &&
                                              a[b.name].length > 75
                                                ? a[b.name].substring(0, 75) +
                                                  "..."
                                                : a[b.name]}
                                            </div>
                                          )}
                                        </Tooltip>
                                      </TableCell>
                                    )
                                  )}
                                  {/*         <TableCell className={monid === a['_record_id'] ? 'queryRowSel' : 'queryRow'} >

                                 
                                    <a href={config.api_base + "reports/" + a['_record_id']} download={ a['_record_id'] + ".pdf"} target="_blank" rel="noreferrer noopener">       
                             
                           <img className="DownloadButton" src={require("../word.jpg")} size="12"/>
                                </a>
&nbsp;
                                <a href={"https://web.fulcrumapp.com/records/" + a['_record_id']} target="_blank" rel="noreferrer noopener">

<img className="DownloadButton" src="https://learn.fulcrumapp.com/img/branding/fulcrum-icon.png"/>
</a>
                               a['Status'] == "QCC" ?
                                  <Tooltip  title={ <span className={classes.Tooltip}><InfoIcon/>This will mark the record as uploaded to EMMA. Please ensure that the report has been downloaded and pushed to EMMA prior to using this.</span>} arrow  TransitionComponent={Zoom}>
                                 <Fab size="small" className="queryHeader" onClick={handleToggle}><LockIcon/></Fab></Tooltip> : ""
                                 <Backdrop className={classes.backdrop} open={open} onClick={handleClose2}>Nothing to see here </Backdrop>
                                      </TableCell>*/}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                          component="div"
                          count={data.rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableContainer>
                    </Paper>
                  </div>
                )}
              </div>
            </TableCell>
            <TableCell className="reportCell" valign="top">
              {doc && (
                <div width="100%" height={pdfHeight}>
                  <div hidden={!showbutt}>
                    <Button
                      disabled={pdfPage === 1}
                      onClick={() => movePage(-1)}
                      color="primary"
                    >
                      Previous Page
                    </Button>{" "}
                    <Button
                      disabled={pdfPage === pdfPages}
                      onClick={() => movePage(1)}
                    >
                      Next Page
                    </Button>
                  </div>

                  <div height={pdfHeight} elevation="4">
                    <Container
                      maxWidth="md"
                      className={classes.container}
                      height={pdfHeight}
                    >
                      <Document
                        elevation="4"
                        renderMode="canvas"
                        loading={<RotateSpinner size={100} color="#686769" />}
                        onLoadSuccess={setPages}
                        file={doc}
                        onLoadError={(error) =>
                          alert(
                            "Error while loading document! " + error.message
                          )
                        }
                        onSourceError={(error) =>
                          alert(
                            "Error while retrieving document source! " +
                              error.message
                          )
                        }
                      >
                        <div elevation={5} width="100%" maxHeight={pdfHeight}>
                          {" "}
                          Page {pdfPage} of {pdfPages}&nbsp;
                          <Page
                            height={pdfHeight}
                            className={classes.container}
                            scale={pdfScale}
                            pageNumber={pdfPage}
                            loading={"Generating page preview...."}
                          />
                        </div>
                      </Document>
                    </Container>{" "}
                  </div>
                </div>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default withRouter(Reports);
