import React  from 'react';
import {BorderStyle,BuildOutlined, SelectAllOutlined,Pets, SpeedOutlined, DeleteOutline, LocalDrink, RemoveCircleOutlineOutlined, SettingsSystemDaydream
, GridOn, LocalGasStation, FilterTiltShift, LocalShipping, WavesOutlined, WrapTextOutlined, MoodBadOutlined } from '@material-ui/icons/'
 
import {faBiohazard,faWind, faFillDrip, faCircle} from '@fortawesome/free-solid-svg-icons'
 import { FontAwesomeIcon } from  '@fortawesome/react-fontawesome'
//Basic function that returns an Icon For a compliance concern group.

const groups = [
    {group:'Site Delineation',icon:<BorderStyle  /> },
    {group:'Construction Activities',icon:<BuildOutlined/> },
    {group:'Wildlife Avoidance Buffers',icon:<SelectAllOutlined/> },
    {group:'Wildlife Entrapment',icon:<Pets/> },
    {group:'Speed Limit',icon:<SpeedOutlined/> },
    {group:'Trash',icon:<DeleteOutline/> },
    {group:'Site Access',icon:<RemoveCircleOutlineOutlined/> },
    {group:'HazMat',icon:<FontAwesomeIcon icon={faBiohazard}/>  },
    {group:'Dust Control',icon:<SettingsSystemDaydream/> },
    {group:'Dust',icon:<FontAwesomeIcon icon={faWind}/> },
    {group:'Mono-Filament',icon:<GridOn/> },
    {group:'Refueling',icon:<LocalGasStation/> },
    {group:'BMP ',icon:<FilterTiltShift/> },
    {group:'Leaks and Containment',icon:<FontAwesomeIcon icon={faFillDrip}/> },
    {group:'Equipment and Vehicles',icon:<LocalShipping/> },
    {group:'Cement and Concrete',icon:<WavesOutlined/> },
    {group:'Wildlife Exclusion Fencing',icon:<WrapTextOutlined/> },
]


export default function GroupIcon(props){


    return(
        <>
      
        {groups.find(a=>a.group===props.group) !== undefined ?  groups.find(a=>a.group===props.group).icon : <MoodBadOutlined/>}
        </>
 
    )
}

export {groups};
