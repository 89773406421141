


  import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Forward from '@material-ui/icons/Forward';
import Whatshot from '@material-ui/icons/Whatshot';
import clsx from 'clsx';
import React from 'react';
import ReactCardFlip from 'react-card-flip';
import { FaFilter as Filter } from 'react-icons/fa';
  const useStyles = makeStyles(theme => ({
    card: {
      maxWidth: 345,textAlign:'center', minHeight:350,
      hover:{
            backgroundColor: '#f00000'
      },
      
    },
    activeFilter:{
      color: 'rgba(220,255,220,0.5)',background : 'rgba(100,200,100,1)'
    },
    noFilter:{

    },
    cardFilter: {
      maxWidth: 345,textAlign:'center', minHeight:350, 
      hover:{
            backgroundColor: 'rgba(0,255,0,0.8)'
      },
      
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
    chart : {
      flexGrow:1,padding:0,margin:0
    }
  }));
  
  export default function DataCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [data,setData] = React.useState(props.data)
    const [flip,setFlip] = React.useState(false);
    const [filterd,setFiltered] = React.useState(false);
    function haveData(){return data!==undefined}
    function handleExpandClick() {
      setExpanded(!expanded);
    }
    function setFilter(){
      props.filter(data.filter)
      console.log('filter')
       setFiltered(true)
    }
    function flippinFunction(){
        setFlip(!flip);
      /*  if(flip)
        {
            setTimeout(() => {
                flippinFunction();
            }, 5000);
        }*/
    }
  
    return (
        
        
        haveData() ?
     <ReactCardFlip className={classes.card} isFlipped={flip} flipDirection={Math.floor(Math.random() * 10) > 4 ? 'vertical' : 'horizontal'}>  
      <Card elevation={4} key="front" className={filterd ? classes.cardFilter : classes.card}>
       
      <Typography variant="h5" color="textSecondary" component="p" style={{align:'center', verticalAlign:'top', textAlign:'middle'}}>
      {data.onFire &&
          <Whatshot style={{color: 'rgba(232,185,16,0.8', fontSize:30}} />
        } {data.title}
        </Typography>
        <CardHeader
       
         
          
         >
           
            </CardHeader>
        <CardContent>
          <Typography variant="h1" color="textSecondary" component="p" style={{align:'center', textAlign:'middle'}}>
        {data.number}
          </Typography>
        </CardContent>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
           {data.text}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
  
  {data.canFilter && 


<IconButton  aria-label="Filter to this data" size="small"  className={filterd ? classes.activeFilter : classes.noFilter}>
<Filter  

 
onClick={setFilter} />
</IconButton >

  }
          {data.detail &&   <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>}
       {data.canFlip &&    <IconButton onClick={flippinFunction}>
              <Forward/>
          </IconButton>}
        </CardActions>
        {data.detail && 
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Detail:</Typography>
            {data.detail.title &&
            <Typography paragraph>
                {data.detail.title}
            </Typography>
            }
            {data.detail.head &&
            <Typography paragraph>
                {data.detail.head}
            </Typography>
            }
            {data.detail.mid &&
            <Typography paragraph>
                {data.detail.mid}
            </Typography>
            }
                 {data.detail.foot &&
            <Typography>
                {data.detail.foot}
            </Typography>
                 }
          </CardContent>
        </Collapse>
                }
      </Card> 
      <Card id="back" key="back"  padding={0} margin={0} className={classes.card}>
        <CardHeader  padding={0} margin={0} disableSpacing
        
          title={data.title}
          
        />
        <CardContent variant="inherit" disableSpacing color="textSecondary" component="div">
                {data.back &&
                    data.back
                }
        
        </CardContent>
        <CardActions disableSpacing>
  
  <IconButton aria-label="Filter to this data"  onClick={()=>props.filter(data.filter)} >
    <Filter />
  </IconButton>
  
  <IconButton onClick={flippinFunction}>
      <Forward/>
  </IconButton>
</CardActions>
      </Card> 
    

      </ReactCardFlip> 
      :
           <div>no renderable data</div>
        
    );
  }