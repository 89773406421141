import {
  AppBar,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GenIcon } from "react-icons";
import config from "../config";
import { Query as qry } from "../lib/fulcrum";
import ComplianceConern from "./hst/complianceConcern";
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const { map } = require("p-iteration");
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));

const store = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [], 8: [] };

export default function Query(props) {
  const { container } = props;
  const classes = useStyles();
  const [maintValue, setMaintValue] = React.useState("all");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [values, setValues] = React.useState({});
  const [selQry, setSelQry] = React.useState(0);
  const [data, setData] = React.useState(undefined);
  const [qTrig, setQTrig] = React.useState(true);
  const [cols, setCols] = React.useState(undefined);
  const [rowKey, setRowKey] = React.useState(undefined);
  const [startDate, setStartDate] = React.useState(new Date().addDays(-365));
  const [endDate, setEndDate] = React.useState(new Date());
  const handleCalendarClose = () => console.log("Calendar closed");
  const handleCalendarOpen = () => console.log("Calendar opened");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [dlEnabled, setDlEnabled] = React.useState(false);
  const [dateType, setDateType] = React.useState("initial_date");
  const [multiStore, setMultiStore] = React.useState(store); //Array of objects {string, array} id,selectedValues
  const [mStore, setMStore] = React.useState({ ...config.CC_Fields });
  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }
  function setKey(key) {
    if (rowKey === key) key = 0;
    setRowKey(key);
  }
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  useEffect(() => {
    if (qTrig) {
      doQuery(false);
      setQTrig(false);
    }
  });

  async function HandleChangeDynaMulti(event) {
    let mStoreTmp = multiStore;
    mStoreTmp[event.target.name] = event.target.value;
    setMultiStore(mStoreTmp);
    handleChange(event);

    /* 
   mStore[event.target.name] = event.target.value;
    let obj = multiStore.find(o=> o.control === event.target.name);
    setSelected(event.target.value);
    //console.log(event.target)
    //console.log(obj);
    if(obj!==undefined){
      const { options } = event.target;
      
     
      obj.selectedValues = event.target.value;
    }else{

    }*/
  }
  async function handledateEndChange(date) {
    setEndDate(date);
    await doQuery(false);
  }
  async function handledateChange(date) {
    setStartDate(date);
    await doQuery(false);
  }
  const getStartDate = startDate;
  const getEndDate = endDate;

  function ChangeOrder(order) {
    order = `[${order}]`;
    let _ob = localStorage.getItem("cc_Order");
    if (_ob === order) order += " desc";
    localStorage.setItem("cc_Order", order);
    doQuery(false);
  }

  function clearFilters() {
    localStorage.removeItem("cc_Query");
    setMultiStore({
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
      7: [],
      8: [],
    });

    setValues({});
    doQuery(true);
    setPage(0);
  }
  async function doQuery(clear) {
    if (props.queryOptions === undefined) return;

    props.loadingCB(true);
    var where = " ";
    var fill = 0;
    //console.log(props.queryOptions[selQry])
    let _ob = localStorage.getItem("cc_Order");
    console.log(values);
    if (!clear) {
      props.queryOptions[selQry].from.map((item, index) => {
        if (
          values[index] !== "All" &&
          values[index] !== undefined &&
          values[index] !== []
        ) {
          where +=
            (fill > 0 ? " AND " : " ") +
            props.queryOptions[selQry].fields[index] +
            " in ('" +
            values[index].join("','") +
            "')";
          fill++;
        }
      });
    }
    var _qry = 'SELECT * from "' + props.queryOptions[selQry].table + '"';
    where +=
      (where === " " ? " " : " AND ") +
      dateType +
      " >= '" +
      getStartDate
        .toLocaleDateString("en-US")
        .replace(new RegExp("/", "g"), "-") +
      "' AND " +
      dateType +
      " <='" +
      getEndDate
        .toLocaleDateString("en-US")
        .replace(new RegExp("/", "g"), "-") +
      "'";
      if(maintValue!=='all'){
         if(where.length>0)
           where+=" AND ";
         if(maintValue==='maint'){
          where+=" maint_item='yes'";
         }else{
          where+=" maint_item<>'yes'";
         }

      }
    //console.log(where);
    if (where !== " ") _qry += " WHERE " + where;
    if (_ob != undefined) _qry += " order by " + _ob + "";
    console.log(_qry);

    let ret = await qry(_qry);
    window.localStorage.setItem("cc_Query", _qry);
    let colsx = [];
    ret.fields.map((a) => colsx.push({ key: a, name: a }));
    //console.log(ret)
    setData(ret);
    setCols(colsx);

    setDlEnabled(ret.rows.length > 0 && ret.rows.length < config.MAX_ITEM_ZIP);

    props.loadingCB(false);
  }
  async function handleChange(event) {
    //console.log(event)

    setValues((oldValues) => ({
      ...oldValues,
      [event.target.name]: event.target.value,
    }));

    // setQTrig(true)
  }
  function getExcel() {
    var base_url = config.api_base + "/Reports/GetComplianceExcel?qry="; //: '/Reports/ComplianceRpt?qry=');
    var uri = base_url + window.localStorage.getItem("cc_Query");
    //console.log(window.localStorage.getItem("cc_Query"));

    window.location.href = uri;
  }
  function getZipReport(type) {
    //base URL
    if (!dlEnabled) {
      alert("Sorry, there's nothing to download");
      return;
    }
    var base_url = config.api_base + "/Reports/" + type + "?qry="; //: '/Reports/ComplianceRpt?qry=');
    var uri = base_url + window.localStorage.getItem("cc_Query");
    //console.log(window.localStorage.getItem("cc_Query"));

    window.location.href = uri;
  }
  const getValue = (name) => {
    //console.log(name);
    let obj = multiStore.find((o) => o.control === name);
    if (obj !== undefined) return obj.selectedValues;
    return [];
  };
  function renderOptions() {}
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      {props.queryOptions && (
        <Paper>
          <AppBar position="static">
            <Typography variant="h6" className={classes.title}>
              Compliance Concerns
            </Typography>
          </AppBar>
          <Paper>
            {" "}
            <form className={classes.root} autoComplete="off">
              <Grid container>
                {props.queryOptions[selQry] &&
                  props.queryOptions[selQry].from.map((item, index) => (
                    <Grid item xs={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor={item}>{item}</InputLabel>
                        <Select
                          multiple
                          value={multiStore[index]}
                          onChange={HandleChangeDynaMulti}
                          minWidth="150"
                          name={index}
                          id={item}
                          input={<Input id={item + "_select-multiple-chip"} />}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  className={classes.chip}
                                />
                              ))}
                            </div>
                          )}
                        >
                          {props.queryOptions[selQry].relatedOptions[index][
                            item
                          ].map((a) => (
                            <MenuItem value={a}>{a}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>{" "}
                    </Grid>
                  ))}
              <Grid item xs={4}></Grid>
             <Grid item xs={2}> 
                <FormControl className={classes.formControl}>
                  <DatePicker
                    todayButton
                    customInput={
                      <TextField
                        name="startDate"
                        id="startDate"
                        label="Start Date"
                      />
                    }
                    selected={startDate}
                    onChange={handledateChange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl className={classes.formControl}>
                  <DatePicker
                    todayButton
                    customInput={
                      <TextField
                        label="End Date"
                        name="endDate"
                        id="endDate"
                        minWidth="10"
                      />
                    }
                    selected={endDate}
                    onChange={handledateEndChange}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </FormControl>{" "}
              </Grid>
              <Grid item xs={2}>
        
              <FormControl className={classes.formControl}>
              <Typography style={{fontSize:'12px',color:'#7C7C7C'}}> 
              <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} style={{fontWeight:'bold'}}>
                    Date Filter Type
                    </Grid>
                    <Grid item xs={4}>Initial</Grid>
                    <Grid item xs={4}>
                    <Switch
                          checked={dateType !== "initial_date"}
                          onChange={() =>
                            setDateType(
                              dateType === "initial_date"
                                ? "resolution_date"
                                : "initial_date"
                            )
                          }
                        ></Switch>
                    </Grid>
                    <Grid item xs={4}>Resolved</Grid>
                    
               
       
                 </Grid> </Typography>
              </FormControl> 
              
              </Grid>
              <Grid item xs={6}>
              <Typography style={{fontSize:'12px',color:'#7C7C7C'}}> 
                <FormControl component="fieldset">
                 

                  <RadioGroup row
                    aria-label="gender"
                    name="concernMaint"
                    value={maintValue}
                    onChange={(e) => setMaintValue(e.target.value)}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      label="Show All" labelPlacement="top"
                    />
                    <FormControlLabel
                      value="concern"
                      control={<Radio />}
                      label="Only Concerns" labelPlacement="top"
                    />
                    <FormControlLabel
                      value="maint"
                      control={<Radio />}
                      label="Only Maintanence" labelPlacement="top"
                    />
                  </RadioGroup>
                </FormControl>
                </Typography>
               </Grid>
                
          
               <Grid item xs={2}>
                <FormControl className={classes.formControl}>
                  {" "}
                  <Button color="primary" onClick={() => doQuery()}>
                    Filter
                  </Button>
                </FormControl>
                </Grid>
                <Grid item xs={2}>
                {
                  <FormControl className={classes.formControl}>
                    {" "}
                    <Button color="primary" onClick={() => clearFilters()}>
                      Clear Filters
                    </Button>
                  </FormControl>
                }
                </Grid>
                <Grid item xs={2}>
                <FormControl className={classes.formControl}>
                  <Button
                    color="primary"
                    disabled={!dlEnabled}
                    onClick={() => getZipReport("ComplianceRpt")}
                  >
                    Report Package
                  </Button>
                </FormControl>
                </Grid>
                <Grid item xs={2}>
                <FormControl className={classes.formControl}>
                  <Button
                    color="primary"
                    disabled={!dlEnabled}
                    onClick={() => getZipReport("ImgPackage")}
                  >
                    Image Package
                  </Button>
                </FormControl>
                </Grid>
                <Grid item xs={2}>
                <FormControl className={classes.formControl}>
                  <Button color="primary" onClick={() => getExcel()}>
                    Excel Report
                  </Button>
                </FormControl>
                </Grid>
                </Grid>
            </form>
          </Paper>
          <Paper>
            {data && (
              <TableContainer>
                <Table className="ccTable" size="small">
                  <TableHead>
                    <TableRow>
                      {config.CC_Fields.map((a) =>
                        a.name.charAt(0) === "_" && a.name !== "__esa_id" ? (
                          " "
                        ) : (
                          <TableCell
                            className="queryHeader"
                            onClick={() => ChangeOrder(a.field)}
                          >
                            {a.name.charAt(0).toUpperCase() +
                              a.name.replace("_", " ").slice(1)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>

                  {data.rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((a, index) => (
                      <>
                        <TableBody>
                          <TableRow
                            onClick={() => setKey(a["_record_id"])}
                            className="queryRow"
                          >
                            {config.CC_Fields.map((b) => (
                              <>
                                <TableCell>
                                  {a[b.field] !== "1/1/1900 12:00:00 AM"
                                    ? a[b.field].replace("12:00:00 AM", "")
                                    : ""}
                                </TableCell>
                              </>
                            ))}
                          </TableRow>
                          {rowKey === a["_record_id"] && (
                            <TableRow>
                              <TableCell colSpan={data.fields.length}>
                                <table>
                                  <tr>
                                    <td>
                                      <ComplianceConern
                                        unrestrictedWidth={true}
                                        noPanel={true}
                                        ccid={a["_record_id"]}
                                      />
                                    </td>
                                  </tr>
                                </table>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </>
                    ))}
                </Table>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                  component="div"
                  count={data.rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableContainer>
            )}
          </Paper>
        </Paper>
      )}
    </div>
  );
}
