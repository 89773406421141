
import {Query,getLineSeries} from './fulcrum'
import { Map, TileLayer, Marker, Popup, CircleMarker, Circle, LayersControl, FeatureGroup } from "react-leaflet";
import React from 'react'
import Config from '../config'

const { map } = require('p-iteration');
//color object is a simple object that has the [colorCol]:Value(color) this is basically the grouping for the row items
//color col is typically your weighting factor - status, size etc
//Must contain fulcrum _record_id
   function RowsToPointLayer(rows,colorObj,colorCol,lat,lng,name,title){
    let dataSplit= []
      rows.map(item => {
        var __name = item[colorCol];
        var objDatax = dataSplit.filter(p=>p._name===__name)
      
            if(objDatax.length===0){
               
               let objData =  
                    {
                    name:name,
                    _name: __name,
                     data : [],

                    color: colorObj[item[colorCol]]
                }
            
                
               
                    objData.data.push({lat:item[lat],lng:item[lng],title:item[title],_record_id:item['_record_id']})  
                     dataSplit.push(objData);
                   
                  
             
            }
          
           
            })
            rows.map(item=>{

                let x =    dataSplit.filter(a=> a._name===item[colorCol]);
                console.log(x.color)

            })

           
    return (
        <LayersControl.Overlay name={name}  checked={true}>
        {dataSplit.map(item=>
   
            <FeatureGroup   color={item.color}>
           
           {
       item.data.map(latlng=>

         item._latitude &&
         <CircleMarker center={[latlng[0],latlng[1]]} fillOpacity="0.75"   radius="10"  key={'m' + item['_record_id']}  position={[item.lat,item.lng]}>
         <Popup key={'p' + latlng[0]} >
           <a href={'https://web.fulcrumapp.com/records/' + item._record_id} target="_new">
           {item.title}</a>
          
         </Popup>
       </CircleMarker>
          
         
         
         )}
           </FeatureGroup>
   
            
            )
       }
            </LayersControl.Overlay>
       
    )
}
async function getCCCounts(filter){
    if(filter===undefined){
    var qry = 'SELECT t1.Concern, t2.OpenCount, t1.TotalCount from (Select Count(*) as TotalCount, site_name as concern from "High Speed Train - Compliance Concerns" GROUP by site_name) t1 RIGHT JOIN (Select Count(*) as OpenCount, site_name as concern from "High Speed Train - Compliance Concerns" where _status <> \'Resolved\' GROUP by site_name) t2 ON t1.concern = t2.concern'
    let ret =  await Query(qry);
 
    return ret.rows
    }
    return null;
}

async function getUniList(fieldName, form){
    if(form==undefined) form="High Speed Train - Compliance Concerns"
    var query = 'SELECT DISTINCT(' + fieldName + ') FROM "' + form + '" ORDER BY ' + fieldName;
    let ret =  await Query(query);
    let list = []
 
    ret.rows.map(a=>
            list.push(a[fieldName])
        )
  
    return list
}
async function getCCVintage(){
    var qry = 'select site_name, compliance_concern, EXTRACT(DAY FROM MAX(NOW())-MIN(_created_at))  as age, _record_id from "High Speed Train - Compliance Concerns" where _status <> \'Resolved\' GROUP BY _record_id, site_name, compliance_concern'
    let ret =  await Query(qry);
 
    return ret.rows
}

async function getCCCountsByType(filter){
    if(filter===undefined){
    var qry = 'SELECT t1.Concern, t2.OpenCount, t1.TotalCount from (Select Count(*) as TotalCount, compliance_concern as concern from "High Speed Train - Compliance Concerns" GROUP by compliance_concern) t1 RIGHT JOIN (Select Count(*) as OpenCount, compliance_concern as concern from "High Speed Train - Compliance Concerns" where _status <> \'Resolved\' GROUP by compliance_concern) t2 ON t1.concern = t2.concern'
    let ret =  await Query(qry);
  
    return ret.rows
    }else{
        var qry = 'SELECT t1.Concern, t2.OpenCount, t1.TotalCount from (Select Count(*) as TotalCount, compliance_concern as concern from "High Speed Train - Compliance Concerns"  AND ' + filter.field + '=\'' + filter.value + ' GROUP by compliance_concern) t1 RIGHT JOIN (Select Count(*) as OpenCount, compliance_concern as concern from "High Speed Train - Compliance Concerns" where _status <> \'Resolved\' AND ' + filter.field + '=\'' + filter.value + ' GROUP by compliance_concern) t2 ON t1.concern = t2.concern'
        let ret =  await Query(qry);
     
        return ret.rows
    }


    return null;
}
export async function getQueryLists(fieldList, table){
    let objReturn = {};

    let x = await fieldList.split(',').map(async a=>
            {
                let r = await getDistinctList(table,a);
                objReturn.push({a: r});
            }
        )

    return objReturn;
}

export async function getCCFilters(){
    let objFilters = Config.CC_QueryFields;
    let objReturn = [];
    let x = objFilters.map(async a=>
        {
            let item  = await getDistinctList('"High Speed Train - Compliance Concerns"',x.field);
           // objReturn.push({<})
        }
        )

}
export async function getDistinctList(table, field){
    var qry = `SELECT distinct(${field}) from ${table} order by ${field}`;
    let ret =  await Query(qry);
    return ret.rows;
}
async function getdm(dmId){
    var qry = 'SELECT * from "High Speed Train Daily Monitoring" where _record_id=\'' + dmId + '\' order by _created_at desc'
    let ret =  await Query(qry);
    return ret
}
async function getBasicMapData(){
    var _qry = 'SELECT _latitude as latitude, compliance_concern as title, _longitude as longitude, _record_id as ccId, compliance_concern_group as grp from vwComplianceConcerns';
    let ret =  await Query(_qry);
    return ret.rows;
}
async function getQCCount(){
    var qry = 'SELECT count(*) as cnt from "High Speed Train Daily Monitoring" where _status = \'qc\' ';
    let ret =  await Query(qry);
    return ret.rows[0]['cnt'];
}
async function getCriticalCCCount(){
    var qry = 'SELECT count(*) as cnt from "High Speed Train - Compliance Concerns" where compliance_concern like \'!%\' AND _status <> \'Resolved\' ';
    let ret =  await Query(qry);
    return ret.rows[0]['cnt'];
}
async function getSr(srId){
    var qry = 'SELECT * from "High Speed Train Site Report" where _record_id=\'' + srId + '\' order by _created_at desc'
    let ret =  await Query(qry);
    return ret
}
async function getCC(ccId){
    var qry = 'SELECT * from "High Speed Train - Compliance Concerns" where _record_id =\'' + ccId + '\' order by _created_at desc';

    let ret =  await Query(qry);
    console.log(ret);
    qry = 'SELECT top 1 * from "High Speed Train - Compliance Concerns!1000!daily_log" where [_parent_id] =\'' + ccId + '\' order by _created_at desc';
    let dl = await Query(qry);
    console.log(dl);
    let objReturn = {cc:ret.rows,dls:dl.rows}
    
    return objReturn
}
async function getSummaryMetrics(){
    var qry = 'SELECT t1.newc, t2.openc, t3.qac,t4.totc,t5.sitec from (select count(*) as newc from "High Speed Train - Compliance Concerns" where _status = \'New\') t1, (select count(*) as openc from "High Speed Train - Compliance Concerns" where _status= \'New\' OR _status=\'On Going\') t2, (select count(*) as qac from "High Speed Train - Compliance Concerns" where _status = \'QA\') t3,  (select count(*) as totc from "High Speed Train - Compliance Concerns"  ) t4, (select max(cnt) as sitec from (SELECT count(*) as cnt,site_name from "High Speed Train - Compliance Concerns" group by site_name)tx) t5'
    let ret =  await Query(qry);
    return ret.rows
}
async function getCCSpatial(){
    var qry = 'Select _latitude,_longitude, _created_latitude,_created_longitude,compliance_concern,_record_id,_status from "High Speed Train - Compliance Concerns" Where resolution_time < \'10-10-2000\' and _latitude is not null'
    let ret = await Query(qry)
    console.log(ret);
    return ret.rows
}
async function getCCDailyLogs(ccId){
    var qry = 'Select * from "High Speed Train - Compliance Concerns/daily_log" where _parent_id=\'' + ccId + '\'';
    let ret = await Query(qry)
    return ret.rows
}
async function getCCByDay(prepfor){
   // var qry ='select count(*) as cnt, to_char(date_trunc(\'day\', _updated_at) as date, \'YYYY-MM-DD\') AS date from "High Speed Train - Compliance Concerns"  GROUP by date'
      var qry ='select count(*) as cnt, dateadd(DAY,0, datediff(day,0, _updated_at)) AS [date] from "High Speed Train - Compliance Concerns"  GROUP by dateadd(DAY,0, datediff(day,0, _updated_at))'
    
   let ret =  await Query(qry);
    if(prepfor===undefined)
        return ret.rows;
    if(prepfor==='line'){
        var x =  getLineSeries(ret.rows,'date','cnt','CCs / time')
        
        return [x];
    }
}


export {getCCByDay,getQCCount,getCCCounts,getCCCountsByType,getSummaryMetrics,getCCSpatial,RowsToPointLayer,getUniList,getCC, getSr,getdm,getCriticalCCCount,getBasicMapData}