import { Backdrop, Divider, Drawer } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from "react";
import { Redirect } from "react-router-dom";
import { PushSpinner } from "react-spinners-kit";
import { AuthConsumer } from "../components/auth/context";
import Login from '../components/Login';
import Logout from '../components/Logout';
import config from '../config';
import { getForms } from "../lib/fulcrum";
 
const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  }, drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  invisBanner:{alignItems:'right',
    backgroundColor: 'rgba(0,21,0,0.0)',flexGrow:1
  }, backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,alignItems:'right',
      backgroundColor: 'rgba(0,21,0,0.0)',flexGrow:1
     
    }),
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,alignItems:'right',
      backgroundColor: 'rgba(0,21,0,0.0)',flexGrow:1
   
    }),
  }, content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,alignItems:'right',
      backgroundColor: 'rgba(0,21,0,0.0)',flexGrow:1
      
    
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const FloatStyle = {
  margin: 0,
  top: 'auto',
  right: 0,
  bottom: 20,
  left: 'auto',
  position: 'fixed',
};



export default function Navbar(props){
 
  const classes = useStyles();
  const [mnu_Open,setMnu_Open] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [redir,setRedir] = React.useState(false);
  const [url,setUrl] = React.useState(false);
  const [forms,setForms] = React.useState(props.forms);
  const [loading,setLoading] = React.useState(props.SiteFlags.loading);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
   
  }
     function renderForms(){
    
    if(forms!==undefined){
        props.cbForms(forms);
  
      
      return(

        <div>you
        
     </div>
      )
      
      
    
    }else{
      setLoading(true)
           getForms(cbForms)
          

    }
  }
  async function setProj(prj){
    handleClose();
    setLoading(true)
    await props.cbProject(prj);
   //var x =  await  getHeatMatrix("High Speed Train - Compliance Concerns","compliance_concern","site_name")
   //console.log(x);
    setLoading(false)
  }
  function cbForms(xforms){
    setForms(xforms);
    setLoading(false);
  }
  function Navto(where){
     
    handleClose();
    setUrl(where);
    setRedir(true);
  }

  function handleClose() {
    setAnchorEl(null);
  }
  return (

  
     
      
      
      
    <AuthConsumer>
    { data  =>   
     
      <AppBar position="sticky"   className={clsx(classes.appBar, {
        [classes.appBarShift]: mnu_Open,
      })}>
 
        <Toolbar style={{ background: config.tbColor, flexGrow:1, width:'98%' }}>
     
        {  data.authenticated && 
 <>
  
        <IconButton onClick={handleClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon/>
          </IconButton>
          <Menu
           id="simple-menu"
           anchorEl={anchorEl}
           keepMounted
           open={Boolean(anchorEl)}
           onClose={handleClose}
         >
             {process.env.NODE_ENV !== "production" ? (
            <MenuItem  onClick={ ()=>Navto('/newReports')} >New Reports</MenuItem>  
            
            

            ) :""}
            <MenuItem  onClick={ ()=>Navto('/hst-dash')} >HST Dashboard</MenuItem>   
            <MenuItem  onClick={ data.logout} >Logout</MenuItem>   
           
  </Menu> 
     </>
      }
      
          <Typography variant="h6" className={classes.title}>
              {config.title}
          
          </Typography>
          <Backdrop className={classes.backdrop} open={loading} >
        <CircularProgress color="inherit" />
      </Backdrop>
          <PushSpinner style={FloatStyle}
                size={22}
                color="rgba(0,255,0,1)"
                loading={loading}/>
   
   
  {  
       <div>{data.user.displayName} 
        {data.user.displayName !== undefined? <Logout/>: <Login/> }
        </div> 
        
      }
  

        </Toolbar>
        
        <Drawer open={mnu_Open}  
                          className={classes.drawer}
                          variant="persistent"
                          anchor="left" onClick={()=>setMnu_Open(false)} 
                          classes={{
                            paper: classes.drawerPaper,
                          }}>

                      ABC
                 <Divider></Divider>
                 DEF
                    </Drawer>
        {redir && <Redirect to={url}/>}

       
      </AppBar>
   
    }
</AuthConsumer>







    

  
    
  );
}

