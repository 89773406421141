import React from 'react';

import ScriptTag from 'react-script-tag';


export default function LogIssue(){



    return  (
        <div className="homeTitle">Please log your issue
     <ScriptTag type="text/javascript" 
     src="https://esa.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/rvt7w7/b/20/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=77b516f8"></ScriptTag>
  
 

</div>
    )
    
}