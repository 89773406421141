import { ResponsiveHeatMap } from '@nivo/heatmap'
import React, { useState, useEffect, Component } from "react";
import { ResponsiveBar } from '@nivo/bar'

export default function HeatMap (props){
function handleClick(e){
    console.log(e)
}
return (
    props.mode === 'hm' || props.mode === undefined ?
    <ResponsiveHeatMap
    data={props.data}
    keys={props.keys}
    indexBy={props.index}
    margin={{ top: 100, right: 60, bottom: 60, left: 60 }}
    forceSquare={false}
    axisTop={{ orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: -90, legend: '', legendOffset: 36 }}
    axisRight={null}
    axisBottom={null}
    colors="oranges"
    minValue={1}
    maxValue={15}
     enableLabels={false}
    cellOpacity={1}
  
    defs={[
        {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(0, 0, 0, 0.1)',
            rotation: -45,
            lineWidth: 4,
            spacing: 7
        }
    ]}
    fill={[ { id: 'lines' } ]}
    padding={2}
    animate={true}
    motionStiffness={80}
    motionDamping={9}
    hoverTarget="cell"
    cellHoverOthersOpacity={0.25}
/>
: 
props.data && (
<ResponsiveBar
 data={props.data}
 keys={props.keys}
 indexBy={props.index}
 margin={{ top: 100, right: 60, bottom: 60, left: 60 }}
 onClick={props.filter!=null?props.filter:handleClick}
 axisTop={{ orient: 'top', tickSize: 5, tickPadding: 5, tickRotation: -45, legend: '', legendOffset: 36 }}
 axisRight={null}
 axisBottom={null}
 borderRadius={2}
 borderWidth={1}
 borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
 colors={{ scheme: 'set1' }}
 colorBy={props.colorBy === undefined ? "index" : props.colorBy}
 defs={[
     {
         id: 'lines',
         type: 'patternLines',
         background: 'inherit',
         color: 'rgba(0, 0, 0, 0.1)',
         rotation: -45,
         lineWidth: 4,
         spacing: 7
     }
 ]}
 
 enableLabel={false}
 animate={true}
 motionStiffness={270}
 motionDamping={10}
 hoverTarget="cell"
 cellHoverOthersOpacity={0.25}

/>)
)

}