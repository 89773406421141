import React from 'react';
import { AuthConsumer } from "../components/auth/context";

export default  function HomePage (props){  
  const [loading,setLoading] = React.useState(props.SiteFlags.loading);
  const [forms] = React.useState(props.forms);
  const [cardData,setCardData] = React.useState(props.CardData)
  const [open, setOpen] = React.useState(false);
 
  return (<div > 
  <img className="bg" src='https://o.aolcdn.com/images/dims?quality=85&image_uri=https%3A%2F%2Fs.aolcdn.com%2Fhss%2Fstorage%2Fmidas%2F4d8959c2b43a0c11f0063b9d82543bf9%2F204910826%2FRTR34NYA-ed.jpg&client=amp-blogside-v2&signature=6b779fec5bb287d7a2548f00a7ecae41a7ef1cb8'/>
<AuthConsumer >
    {context =>

     context.isAuthenticated  ?  
     
     
     
      
       
  
      <div>
        <div className="homeTitle">
          California High Speed Rail
        </div>
                <div className="homeText">Field data collection, compliance tracking  & reporting system</div>

                </div>
       : (
        
        
      <div align="middle"><br></br><br></br><br></br><br></br><br></br>
        {process.env.NODE_ENV !== "production"  &&
           <button onClick={()=>localStorage.clear()}>Clear Storage</button>
        }
        <div className="homeTitle">
          California High Speed Rail
        </div>
        <div className="homeText">Field data collection, compliance tracking  & reporting system</div>
        </div>     
        
  
      )
    }
  </AuthConsumer>    </div>
);

  }