import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';
import DataCard from '../dataCard'
import {FaRetweet} from 'react-icons/fa'
import IconButton from '@material-ui/core/IconButton'
import { TransitionGroup, CSSTransition } from "react-transition-group";
const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
 
    control: {
      padding: theme.spacing(2),
    },
  }));
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }
  
  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  };
export default function CardDeck(props){
    const classes = useStyles();
    const [deck,setDeck] = React.useState(true)
    const [cdata,setcData]= React.useState(props.data)
    const [inProp, setInProp] =  React.useState(false);
    const [cdata2,setcData2]= React.useState(props.data2)
    function acceptFilter(filter){
   
      if(props.filter!==undefined)
          props.filter(filter)
    }
   async function changeDeck(){
        setDeck(!deck)
        console.log(deck)
      
    }
    return(
    <div style={{alignContent:'right', alignItems:'right'}}> 
      
 
        <Grid container className={classes.root} spacing={2}>
            {deck &&
            cdata.map(item=>
                <CSSTransition ansition classNames={transitionStyles} timeout={300}>
                    <Grid item xs={2}><DataCard filter={acceptFilter} data={item}/></Grid>
                    </CSSTransition>
                )}
         {!deck && 
            cdata2.map(item=>
                
                <Grid item xs={2}><DataCard  className={classes.root}  filter={acceptFilter} data={item}/></Grid>
            
            )
            }
        </Grid>
        
        </div>

    )



    



}