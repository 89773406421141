import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
 
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { AuthConsumer } from "../components/auth/context";
import Can from "../components/Can";
import CardDeck from '../components/cards/CardDeck';
import Pie from '../components/charting/pie';
import Query from '../components/query';
import Reports from '../components/reports';
import Map from '../components/mapping/glMap'
 
 
import { PulseSpinner, RotateSpinner,SequenceSpinner } from 'react-spinners-kit';
 

function HSTDash(props) {
  const { children, value, index, ...other } = props;
 
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={4}>{children}</Box>
   
    </Typography>
  );
}

HSTDash.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${ index}`,
    'aria-controls': `simple-tabpanel-${ index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ededed',
  },
}));

 function SimpleTabs(props) {
  const classes = useStyles();
  const [loading,setLoading] = React.useState(false);
  const [value, setValue] = React.useState(  0);
  const [flipped, setFlipped] = React.useState(false)
  console.log(localStorage.getItem('tab'))
  function acceptFilter(filter){
    console.log('Dash :: ' + filter)
    if(props.filter!==null)
      props.filter(filter)
  }
  function isLoading(val){
    setLoading(val);
  }
  function handleChange(event, newValue) {
    setValue(newValue);

    localStorage.setItem('tab', newValue);
  }
  function flip(){
 
    setFlipped(!flipped)
  }

  return (
    <AuthConsumer>
    {( user ) => (
    
      <Can 
        role={user.role}
        perform="dashboard-page:visit"
        yes={() => (
 
    
    <div className={classes.root}>
      
      <AppBar position="static">
    


 
       <table>
         <tr><td id="MainContentArea">
        <Tabs value={value} onChange={handleChange} aria-label="">
          <Tab label={"Dashboard"} {...a11yProps(0)} />     
          <Tab label="Daily Monitoring" {...a11yProps(1)} />
          <Tab label="Compliance Concerns" {...a11yProps(2)} />
          <Tab label="Map of Concerns" {...a11yProps(3)} />
      
        </Tabs>
        </td>
        {loading && 
        <td>  <SequenceSpinner color="#94FFEF" size="40"/></td>
        }
        </tr>
        </table>
       
      </AppBar>
      <HSTDash value={value} index={0} >
      
     
          {props.cards &&
      
          <CardDeck id='cards-set1'     filter={acceptFilter} data2={props.cards2} data={props.cards} />
          }
           
        <Pie filter={acceptFilter} data={props.piData} cb={props.cb} loadingCB={setLoading}></Pie>
     
      </HSTDash>
      <HSTDash value={value} index={3}>
      <Map data={props.mapData}  ></Map> 
        {console.log(props.mapData)}
        {  /* value==3 &&  <Map data={props.piData}/>
        <SimpleMap width="100%"/>*/
    
        
    
          
        }
      </HSTDash>
      <HSTDash value={value} index={2}>
        <Query data={props.queryData} queryOptions={props.queryOptions} loadingCB={setLoading}/>
      </HSTDash>
      <HSTDash value={value} index={1}>
         

        <Reports  queryOptions={props.queryOptions} loadingCB={setLoading}/>
      </HSTDash>
    </div>
        )}
      
        no={  () =>  <Redirect to="/#/NoAccess" />   }
      />
    )}
  </AuthConsumer>
  );
}


export default withRouter(SimpleTabs);
